//@ts-check
import { updateCircle } from '@/api/circle'
import { convertClientPricesToServerPrices, convertServerPricesToClientPrices } from '@/utils'
import { whenever } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useCirclePresent } from './circle_present'
import { useCurrentCurrency } from './currency'
import { useToast } from './toast'

export const useCircleEdit = ({ coverUrl }) => {
  const { currency } = useCurrentCurrency()
  const circleId = useRoute().params.circleId
  const { circle } = useCirclePresent(currency, { fromNetwork: true })
  const title = ref('')
  const description = ref('')
  const introduction = ref('')
  const loading = ref(false)
  const router = useRouter()
  const { Toast } = useToast()
  const { t } = useI18n()
  const salesType = ref(null)
  const forceComplement = ref(false)
  const defaultCurrency = ref('JPY')
  const pricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    },
  })
  const upfrontPricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    },
  })
  const complementPricesData = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      EUR: 0,
      USD: 0,
    },
  })

  const prices = computed(() => {
    return pricesData.value.prices
  })

  const upfrontPrices = computed(() => {
    return upfrontPricesData.value.prices
  })

  const complementPrices = computed(() => {
    return complementPricesData.value.prices
  })

  whenever(circle, () => {
    title.value = circle.value.title
    description.value = circle.value.description
    introduction.value = circle.value.introduction
    coverUrl.value = circle.value.coverUrl
    salesType.value = circle.value.salesType
    forceComplement.value = circle.value.forceComplement
    defaultCurrency.value = circle.value.defaultCurrency

    // Convert server prices to client prices
    pricesData.value.prices = convertServerPricesToClientPrices(circle.value.prices)
    upfrontPricesData.value.prices = convertServerPricesToClientPrices(circle.value.upfrontPrices)
    complementPricesData.value.prices = convertServerPricesToClientPrices(circle.value.complementPrices)

    // Set default currency price for each price type
    if (defaultCurrency.value in circle.value.prices) {
      pricesData.value.defaultCurrency = defaultCurrency.value
      pricesData.value.price = pricesData.value.prices[defaultCurrency.value]
    }

    if (defaultCurrency.value in circle.value.upfrontPrices) {
      upfrontPricesData.value.defaultCurrency = defaultCurrency.value
      upfrontPricesData.value.price = upfrontPricesData.value.prices[defaultCurrency.value]
    }

    if (defaultCurrency.value in circle.value.complementPrices) {
      complementPricesData.value.defaultCurrency = defaultCurrency.value
      complementPricesData.value.price = complementPricesData.value.prices[defaultCurrency.value]
    }
  })

  const update = async function () {
    try {
      const submitPrices = {}

      // Prepare price data for submission
      if (pricesData.value.prices[defaultCurrency.value] > 0) {
        submitPrices.prices = convertClientPricesToServerPrices(prices.value)
      }

      if (complementPricesData.value.prices[defaultCurrency.value] > 0) {
        submitPrices.complementPrices = convertClientPricesToServerPrices(complementPrices.value)
      }

      if (upfrontPricesData.value.prices[defaultCurrency.value] > 0) {
        submitPrices.upfrontPrices = convertClientPricesToServerPrices(upfrontPrices.value)
      }

      loading.value = true
      await updateCircle(circleId, {
        title: title.value,
        description: description.value,
        introduction: introduction.value,
        coverUrl: coverUrl.value,
        defaultCurrency: pricesData.value.defaultCurrency,
        ...submitPrices,
      })
      Toast(t('success'), 'ok')
      router.push(`/circles/${circleId}`)
    } catch (err) {
      Toast(err.message)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    title,
    description,
    introduction,
    update,
    coverUrl,
    pricesData,
    upfrontPricesData,
    complementPricesData,
    defaultCurrency,
    forceComplement,
    salesType,
  }
}
