<template>
  <drawer
    v-model:visible="visible"
    position="full"
    class="media-browser-drawer"
  >
    <div
      v-for="(media, $index) in medias"
      :key="media._id"
      v-show="index === $index"
      class="media-browser-content"
    >
      <transition-image
        v-if="media.type === MediaType.image"
        :src="media.url"
        :thumbnail="media.thum"
        :styles="{
          maxWidth: '100%',
          maxHeight: '100%',
          height: '100%',
          aspectRatio: media.width / media.height,
          objectFit: 'contain',
        }"
        :show-progress-bar="true"
        data-browser-view="true"
        class="browser-image"
      >
      </transition-image>
      <div
        v-else-if="media.type === MediaType.video"
        class="video-container"
      >
        <video-player
          :media="media"
          :artwork-id="media.postId"
          :paid="paid"
        ></video-player>
      </div>
    </div>

    <div class="media-browser-footer">
      <div
        v-for="(media, $index) in medias"
        :key="media._id"
        class="item-preview hoverable touchable"
        @click="index = $index"
      >
        <img
          v-if="media.type === MediaType.video"
          style="
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          src="@/assets/video_tag.png"
        />
        <transition-image
          :src="media.thum"
          style="width: 100%; height: 100%; min-width: 5rem; min-height: 5rem"
          :styles="{
            width: '100%',
            height: '100%',
            minWidth: '5rem',
            minHeight: '5rem',
            objectFit: 'cover',
          }"
        ></transition-image>
      </div>
    </div>
  </drawer>
</template>

<script>
//@ts-check
import TransitionImage from '@/components/TransitionImage.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { useApplication } from '@/pinia/application'
import { useArtworkStore } from '@/pinia/artwork'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'
import { MediaType } from '@perohub/libpero/dist/types'
import Drawer from 'primevue/drawer'
import Galleria from 'primevue/galleria'
import { computed, ref, watch } from 'vue'

export default {
  setup() {
    const application = useApplication()
    const index = ref(0)
    const showPreviews = ref(true)
    const artwortStore = useArtworkStore()

    const visible = computed({
      get() {
        return application.browserVisible
      },
      set(value) {
        if (!value) {
          application.closeBrowser()
        }
      },
    })

    const paid = computed(() => {
      if (
        application.browserArtworkId &&
        artwortStore.artworks[application.browserArtworkId]
      ) {
        return artwortStore.artworks[application.browserArtworkId].paid
      }

      // adapt for stars page
      return true
    })

    const medias = computed(() => {
      return application.browserMedias.filter(
        (media) =>
          media.type === MediaType.image || media.type === MediaType.video,
      )
    })

    const selectedMedia = computed(() => {
      return medias.value[index.value]
    })

    watch(visible, () => {
      if (visible.value) {
        index.value = application.browserIndex
        if (application.browserArtworkId) {
          eventEmitter.emit(events.ViewerEntered, application.browserArtworkId)
        }
      } else {
        if (application.browserArtworkId) {
          eventEmitter.emit(
            events.ViewerQuitOnIndex,
            application.browserArtworkId,
            index.value,
          )
        }
        // if we browserArtworkId to null in closeBrowser, this watcher will not execute forever
        application.$patch({ browserArtworkId: null })
      }
    })

    return {
      visible,
      medias,
      index,
      showPreviews,
      paid,
      MediaType,
      selectedMedia,
    }
  },
  components: {
    Drawer,
    VideoPlayer,
    Galleria,
    TransitionImage,
  },
}
</script>

<style>
.media-browser-drawer {
  background-color: #212121 !important;
}

.media-browser-drawer .p-drawer-content {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.browser-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
.media-browser-content {
  height: calc(100vh - 13.5rem);
  display: flex;
  flex-direction: column;
  border: none !important;
}

.media-browser-footer {
  height: 10rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.item-preview img {
  object-fit: contain;
  max-width: 3rem;
  max-height: 3rem;
}

.item-preview {
  position: relative;
}

.video-container {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
</style>
