<template>
  <div
    ref="clusterRefDom"
    class="user-avatars-container"
    :style="{
      gap: gap,
    }"
  >
    <router-link v-for="user in users" :key="user._id" :to="`/users/${user._id}`">
      <user-avatar style="width: 2.6rem; height: 2.6rem" :user="user" />
    </router-link>
  </div>
</template>

<script>
import { useElementHover } from '@vueuse/core'
import { computed, ref } from 'vue'
import UserAvatar from './UserAvatar.vue'

export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const clusterRefDom = ref(null)

    const expand = computed(() => {
      return useElementHover(clusterRefDom)
    })

    const gap = computed(() => {
      if (expand.value) {
        return '-0.5rem'
      }
      return '0.5rem'
    })

    return {
      gap,
    }
  },
  components: {
    UserAvatar,
  },
}
</script>

<style scoped>
.user-avatars-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
</style>
