//@ts-check
import { useApplication } from '@/pinia/application'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useConfirm } from './confirm'
import { useCurrentCurrency } from './currency'
import { useToast } from './toast'
import { useCurrentUser } from './user_data'

export const useUserContact = function (targetUser) {
  const { currency } = useCurrentCurrency()
  const route = useRoute()
  const { t } = useI18n()
  const application = useApplication()
  const { Toast } = useToast()
  const { confirm } = useConfirm()
  const { authenticated } = useCurrentUser()

  const vipPrice = computed(() => {
    return convertServerPriceToClientPrice(
      targetUser.vipPrices[currency.value],
      currency.value,
    )
  })

  watch(targetUser, () => {
    showContactIfNeeded()
  })

  const intentContactBuy = function () {
    if (!authenticated.value) {
      return application.showLogin()
    }
    application.openPaymentPicker({
      userId: targetUser._id,
      // processors: ['stripe'],
      processors: ['order2faka', 'alipay', 'wechat'],
    })
  }

  const showContactIfNeeded = function () {
    if (route.query.showContact && targetUser._id) {
      if (targetUser.vip) {
        confirm({
          header: t('aisatuTokenTitle', {
            code: targetUser.vipCode,
          }),
          message: targetUser.contactInfo,
          acceptLabel: t('confirm'),
        })
      }
    }
  }

  const buyContactClicked = async function () {
    try {
      if (targetUser.vip) {
        confirm({
          header: t('aisatuTokenTitle', {
            code: targetUser.vipCode,
          }),
          message: targetUser.contactInfo,
          acceptLabel: t('confirm'),
        })
      } else {
        confirm({
          message: t('aisatuUnlockText', {
            price: `${currency.value} ${vipPrice.value}`,
            nickname: targetUser.nickname,
          }),
          accept: () => {
            intentContactBuy()
          },
        })
      }
    } catch (err) {
      if (err !== 'cancel') {
        Toast({
          message: err.message,
        })
      }
    }
  }

  onMounted(() => {
    showContactIfNeeded()
  })

  return {
    buyContactClicked,
  }
}
