<template>
  <div v-if="isOnMobile">
    <div v-for="post in posts.filter((post) => post !== undefined)" :key="post._id" class="item">
      <router-link :to="post.type === 'comics' ? `/comics/${post._id}` : `/posts/${post._id}`">
        <post-list-item :post="post"></post-list-item>
      </router-link>
    </div>
  </div>
  <masonry v-else :cols="3" :gutter="10">
    <div v-for="post in posts.filter((post) => post)" :key="post._id" class="common">
      <router-link :to="post.type === 'comics' ? `/comics/${post._id}` : `/posts/${post._id}`">
        <post-list-item :post="post"></post-list-item>
      </router-link>
    </div>
  </masonry>
</template>

<script>
import Masonry from '@/components/Masonry.vue'
import PostListItem from '@/components/PostListItem.vue'
import { useResponsive } from '@/composables/responsive'

export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      ...useResponsive(),
    }
  },
  components: {
    PostListItem,
    Masonry,
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.common {
  width: 100%;

  @media @phone {
    margin-bottom: 1em;
  }
}
</style>
