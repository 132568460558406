//@ts-check
import postapi, { getPostTranslation } from '@/api/post'
import { useToast } from '@/composables/toast'
import { eventEmitter } from '@/utils'
import { events } from '@/utils/events'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAuthenticator } from './authenticator'

export const useArtworkStore = defineStore('artwork', () => {
  const artworks = ref({}) // artworkId: artwork
  const userArtworks = ref({}) // userId: { latestPostIds: [], hotPostIds: [] }
  const authenticator = useAuthenticator()
  const { Toast } = useToast()

  const getArtworkById = async function (artworkId, forceUpdate = false) {
    try {
      if (artworks.value[artworkId] && !forceUpdate) {
        return
      }

      await authenticator.checkAuth()
      const [artwork, contentLocales] = await Promise.all([postapi.getOne(artworkId), getPostTranslation(artworkId)])

      if (artwork) {
        artwork.contentLocales = contentLocales
        cacheArtwork(artwork)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const cacheArtwork = function (artwork) {
    if (!artwork) {
      return
    }

    artworks.value[artwork._id] = artwork
  }

  const attachTranslations = function (artworkId, contentLocales) {
    if (artworks.value[artworkId]) {
      artworks.value[artworkId].contentLocales = contentLocales
    }
  }

  const initUserCacheIfNeeded = function (userId) {
    if (!userArtworks.value[userId]) {
      const data = {
        latestPostIds: [],
        hotPostIds: [],
      }

      userArtworks.value[userId] = data
    }
  }

  const pushUserHotArtwork = function (userId, artworkId) {
    initUserCacheIfNeeded(userId)

    if (!userArtworks.value[userId].hotPostIds.includes(artworkId)) {
      userArtworks.value[userId].hotPostIds = [...userArtworks.value[userId].hotPostIds, artworkId]
    }
  }

  const pushUserLatestArtwork = function (userId, artworkId) {
    initUserCacheIfNeeded(userId)

    if (!userArtworks.value[userId].latestPostIds.includes(artworkId)) {
      userArtworks.value[userId].latestPostIds = [...userArtworks.value[userId].latestPostIds, artworkId]
    }
  }

  const like = function (postId) {
    artworks.value[postId].liked = true
    artworks.value[postId].likes += 1
  }

  const star = function (postId) {
    artworks.value[postId].starred = true
    artworks.value[postId].starCount += 1
  }

  eventEmitter.on(events.LoginCompleted, () => {
    artworks.value = {}
  })
  eventEmitter.on(events.LogoutCompleted, () => {
    artworks.value = {}
  })

  return {
    artworks,
    getArtworkById,
    cacheArtwork,
    like,
    star,
    pushUserHotArtwork,
    pushUserLatestArtwork,
    attachTranslations,
    userArtworks,
  }
})
