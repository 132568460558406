//@ts-check
import { MediaType } from '@perohub/libpero/dist/types'
import { computed } from 'vue'

export const useArtworkMediasCount = (post) => {
  const mediasCount = computed(() => {
    if (!post) {
      return 0
    }
    return post.pictures.length
  })

  const imageCount = computed(() => {
    if (!post) {
      return 0
    }
    return post.pictures.filter((picture) => picture.type === MediaType.image).length
  })

  const videoCount = computed(() => {
    if (!post) {
      return 0
    }
    return post.pictures.filter((picture) => picture.type === MediaType.video).length
  })

  return {
    mediasCount,
    imageCount,
    videoCount,
  }
}
