//@ts-check
import { withdraw as apiWithdraw, getStripeLoginUrl } from '@/api/user'
import { useUserBalance } from '@/composables/user_balance'
import { useCurrentUser } from '@/composables/user_data'
import { useAuthenticator } from '@/pinia/authenticator'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useToast } from './toast'

export const useUserPayout = function () {
  const { balances } = useUserBalance()
  const showWithdraw = ref(false)
  const withdrawing = ref(false)
  const { t } = useI18n()
  const router = useRouter()
  const authenticator = useAuthenticator()
  const { currentUser: user } = useCurrentUser()
  const withdraws = ref({
    CNY: 0,
    USD: 0,
    JPY: 0,
    EUR: 0,
  })
  const { Toast } = useToast()

  const balancesKV = computed(() => {
    const kv = {}
    // @ts-ignore
    for (const balance of balances.value.values()) {
      kv[balance.currency] = convertServerPriceToClientPrice(
        balance.amount,
        balance.currency,
      )
    }

    return kv
  })

  const withdraw = function () {
    // @ts-ignore
    if (!user.value.payoutEnabled) {
      return router.push('/i/wallet/bind')
    }

    showWithdraw.value = true
  }

  const goToStripeDashboard = async function () {
    try {
      withdrawing.value = true
      // @ts-ignore
      const url = await getStripeLoginUrl(user.value._id)
      window.location.href = url
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      withdrawing.value = false
    }
  }

  const submitWithdraw = async function () {
    try {
      withdrawing.value = true

      const datas = []
      // @ts-ignore
      const balancesUpdater = Object.assign({}, user.value.balances)

      for (const currency of Object.keys(withdraws.value)) {
        let amount = Number(withdraws.value[currency])
        if (amount <= 0) {
          continue
        }
        if (currency !== 'JPY') {
          amount = Math.round(amount * 100)
        } else {
          amount = Math.round(amount)
        }
        datas.push({
          currency,
          amount,
        })

        balancesUpdater[currency] -= amount
      }

      await apiWithdraw(user.value._id, datas)
      await authenticator.refreshUser()

      Toast(
        {
          message: t('payoutApplied'),
        },
        'ok',
      )

      // @ts-ignore
      if (user.value.info.payoutMethod === 'stripe') {
        await goToStripeDashboard()
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      withdrawing.value = false
    }
  }

  const add = function (currency) {
    const before = Number(withdraws.value[currency])
    if (before + 1 <= balancesKV.value[currency]) {
      let value = withdraws.value[currency] * 100
      value += 100
      value = Number((value / 100).toFixed(2))
      withdraws.value[currency] = value
    } else {
      withdraws.value[currency] = balancesKV.value[currency]
    }
  }

  const minus = function (currency) {
    const before = Number(withdraws.value[currency])
    if (before - 1 >= 0) {
      let value = withdraws.value[currency] * 100
      value -= 100
      value = Number((value / 100).toFixed(2))
      withdraws.value[currency] = value
    } else {
      withdraws.value[currency] = 0
    }
  }

  onMounted(() => {
    // @ts-ignore
    for (const balance of balances.value.values()) {
      withdraws.value[balance.currency] = convertServerPriceToClientPrice(
        balance.amount,
        balance.currency,
      )
    }
  })

  return {
    withdraw,
    submitWithdraw,
    showWithdraw,
    withdraws,
    balances,
    add,
    minus,
    withdrawing,
    goToStripeDashboard,
  }
}
