<template>
  <div class="container">
    <div
      v-for="(image, index) in images.filter((image) => image.canBrowse || paid)"
      :key="index"
      class="image"
      :class="{ selected: image.selected }"
      @click.stop="toggleSelection(image._id, index)"
    >
      <img :src="image.thum" :class="{ selected: image.selected }" />
      <img v-if="image.type === MediaType.video" class="play-button" src="@/assets/video_tag.png" />
    </div>
  </div>
</template>

<script>
//@ts-check
import { MediaType } from '@perohub/libpero/dist/types'
import { ref } from 'vue'

export default {
  props: {
    postId: {
      type: String,
      required: true,
    },
    pics: {
      type: Array,
      default() {
        return []
      },
    },
    paid: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const images = ref([])

    return {
      images,
      MediaType,
    }
  },
  computed: {
    contentIds() {
      return this.images.filter((image) => image.selected).map((image) => image._id)
    },
  },
  watch: {
    /*postId (value) {
      if (value) {
        api
          .getOne(this.postId)
          .then(post => {
            this.paid = post.paid

            post.pictures.forEach(picture => {
              picture.selected = false
              this.images.push(picture)
            })
          })
          .catch(err => {
            console.error(err) 
          })
      }
    },*/
    pics(value) {
      if (value && value.length > 0) {
        value.forEach((picture) => {
          picture.selected = false
          this.images.push(picture)
        })
      }
    },
  },
  mounted() {},
  methods: {
    toggleSelection(contentId, index) {
      //alert(this.images[index].selected)
      this.images[index].selected = !this.images[index].selected
      this.$emit('valuechange', this.contentIds)
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 95px;

  .image {
    display: inline-block;
    box-sizing: border-box;
    width: 68px;
    height: 68px;
    margin-left: 10px;
    position: relative;

    img {
      width: 64px;
      height: 64px;
      left: 1px;
      top: 1px;
      object-fit: cover;
      position: absolute;

      &.selected {
        left: 0;
        top: 0;
      }
    }

    .play-button {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 17px;
      left: 17px;
    }

    &.selected {
      border: 2px solid @positive;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
