<template>
  <div
    class="transition-image"
    :class="classNames.join(' ')"
    :style="{
      width: styles.width,
      height: styles.height,
      pointerEvents: styles.pointerEvents,
      aspectRatio: styles.aspectRatio,
      borderRadius: styles.borderRadius,
    }"
  >
    <skeleton
      v-show="!loaded"
      ref="loadingPlaceholder"
      :style="{
        width: styles.width,
        height: styles.height,
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
      }"
    >
    </skeleton>
    <progress-bar
      v-if="showProgressBar && loadingProgress < 100"
      :value="loadingProgress"
      :mode="loadingProgress === null ? 'indeterminate' : 'determinate'"
      class="image-progress-bar"
      :style="{
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        height: '4px',
        zIndex: '10',
        borderRadius: '0',
      }"
      :show-value="false"
    />
    <img
      v-show="thumbnail && !displayFullImage"
      ref="thumbnailDom"
      crossorigin="anonymous"
      :src="thumbnail"
      :alt="alt"
      :style="{
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
      }"
    />
    <img
      v-show="actualImageSrc"
      ref="fullImageDom"
      crossorigin="anonymous"
      :style="{
        ...innerStyle,
        objectFit: styles.objectFit,
        aspectRatio: styles.aspectRatio,
        pointerEvents: styles.pointerEvents,
        minWidth: styles.minWidth,
        minHeight: styles.minHeight,
      }"
      :src="actualImageSrc"
      :alt="alt"
      :loading="thumbnail ? 'eager' : 'lazy'"
      @error="handleFullImageError"
    />
  </div>
</template>

<script>
//@ts-check
import { useImageCacherStore } from '@/pinia/image_cacher'
import { until } from '@vueuse/core'
import ColorThief from 'colorthief'
import ProgressBar from 'primevue/progressbar'
import Skeleton from 'primevue/skeleton'
import { nextTick, onMounted, ref, watch } from 'vue'

// @ts-ignore
const colorthief = new ColorThief()
const errorUrl = 'https://placehold.co/100x100?text=failed'

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    classNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    animated: {
      type: Boolean,
      required: false,
      default: true,
    },
    showProgressBar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['on-dominant-color'],
  setup(props, { emit }) {
    const imageCacher = useImageCacherStore()
    const displayFullImage = ref(props.thumbnail === '')
    const fullImageDom = ref(null)
    const thumbnailDom = ref(null)
    const innerStyle = ref({
      opacity: 0,
    })
    const loaded = ref(false)
    const loadingProgress = ref(0)
    const loadingPlaceholder = ref(null)
    const actualImageSrc = ref('')
    let dominantColorTries = 0
    const maxDominantColorTries = 10
    let hookedFullDom = false
    let hookedThumbnailDom = false

    // Feature detection for progress tracking capability
    const canTrackProgress = () => {
      return (
        window.fetch && window.ReadableStream && 'body' in Response.prototype
      )
    }

    watch(fullImageDom, () => {
      hookupIfNeeded()
    })

    watch(thumbnailDom, () => {
      hookupIfNeeded()
    })

    watch(props, () => {
      hookupIfNeeded()
    })

    const hookupIfNeeded = async function () {
      if (props.thumbnail && thumbnailDom.value && !hookedThumbnailDom) {
        await until(thumbnailDom).toMatch((dom) => dom !== null)
        thumbnailDom.value.onload = () => {
          innerStyle.value.opacity = 1
          loaded.value = true
        }
        hookedThumbnailDom = true
      }

      // Create a reusable animation function
      const animateImageFadeIn = (isThumbnailImage = false) => {
        if (props.animated) {
          const animate = function () {
            const value = innerStyle.value.opacity + 0.1
            innerStyle.value.opacity = Math.min(value, 1)

            if (innerStyle.value.opacity < 1) {
              return requestAnimationFrame(animate)
            }

            // Notify for dominant color
            if (isThumbnailImage && props.thumbnail && props.src) {
              // both thumbnail and src provided, means it is displaying a large image
              notify(fullImageDom.value)
            } else if (!isThumbnailImage) {
              notify(fullImageDom.value)
            }
          }

          requestAnimationFrame(animate)
        } else {
          innerStyle.value.opacity = 1
          notify(fullImageDom.value)
        }
      }

      if (props.src && fullImageDom.value && !hookedFullDom) {
        // If no thumbnail is specified, just set the src directly and skip caching/progress
        if (!props.thumbnail) {
          actualImageSrc.value = props.src
          innerStyle.value.opacity = 0 // Start with opacity 0 for animation
          loadingProgress.value = 100
          loaded.value = true

          await until(fullImageDom).toMatch((dom) => dom !== null)
          fullImageDom.value.onload = () => {
            displayFullImage.value = true
            // Use the reusable animation function
            animateImageFadeIn()
          }
          hookedFullDom = true
          return
        }

        // Check for cached version first
        const cachedUrl = imageCacher.getCachedUrl(props.src)
        if (cachedUrl) {
          // If we have a cached version, use it immediately
          actualImageSrc.value = cachedUrl
          innerStyle.value.opacity = 1
          loadingProgress.value = 100
          loaded.value = true

          // Still set up the onload handler for DOM events
          await until(fullImageDom).toMatch((dom) => dom !== null)
          fullImageDom.value.onload = () => {
            displayFullImage.value = true
            notify(fullImageDom.value)
          }
          hookedFullDom = true
          return
        }

        // Choose loading method based on capabilities and preferences
        if (props.showProgressBar && canTrackProgress()) {
          try {
            // Use the image cache store to load and track progress
            actualImageSrc.value = await imageCacher.loadImage(
              props.src,
              (progress) => {
                loadingProgress.value = progress
              },
            )
          } catch (e) {
            console.warn(
              'Failed to load image with cache, falling back to direct URL',
              e,
            )
            actualImageSrc.value = props.src
          }
        } else {
          // Either progress bar not requested or browser doesn't support tracking
          if (props.showProgressBar) {
            // Show indeterminate progress when tracking not available
            loadingProgress.value = null
          }

          // If we get here, we know there's no cached version (we checked above)
          actualImageSrc.value = props.src
        }

        await until(fullImageDom).toMatch((dom) => dom !== null)
        fullImageDom.value.onload = () => {
          displayFullImage.value = true
          loaded.value = true
          loadingProgress.value = 100

          // Use the reusable animation function with the thumbnail flag
          animateImageFadeIn(true)
        }
        hookedFullDom = true
      }
    }

    const notify = function (dom) {
      nextTick(() => {
        if (dominantColorTries >= maxDominantColorTries) {
          return console.log('get dominant colors max tries reached')
        }

        if (dom) {
          emit('on-dominant-color', colorthief.getColor(dom))
        } else {
          dominantColorTries += 1
          notify(dom)
        }
      })
    }

    const handleFullImageError = (err) => {
      if (!actualImageSrc.value) {
        return
      }

      console.error('Failed to load image', err)
      nextTick(async () => {
        actualImageSrc.value = errorUrl
      })
    }

    onMounted(() => {
      hookupIfNeeded()
    })

    return {
      fullImageDom,
      thumbnailDom,
      displayFullImage,
      innerStyle,
      loaded,
      loadingProgress,
      loadingPlaceholder,
      actualImageSrc,
      handleFullImageError,
    }
  },
  components: {
    Skeleton,
    ProgressBar,
  },
}
</script>

<style>
.transition-image {
  position: relative;
  overflow: hidden;
}

.transition-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}

.image-progress-bar {
  overflow: hidden;
}

.image-progress-bar .p-progressbar-value {
  transition: width 0.2s ease-in-out;
}
</style>
