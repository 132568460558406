<template>
  <div class="container">
    <div class="records">
      <div
        v-for="record in records"
        :key="record._id"
        class="record"
      >
        <div class="description-wrapper">
          <span class="description">
            <user-avatar
              v-if="record.order"
              :user="record.order.payer"
              style="width: 1.5em; height: 1.5em"
              @click="$router.push(`/users/${record.order.payer._id}`)"
            ></user-avatar>
            {{ record.description }}
          </span>
          <span class="time">
            {{ new Date(record.createdAt).toLocaleString() }}
          </span>
        </div>
        <span
          class="coins"
          :class="{
            income: record.type.indexOf('income') !== -1,
            expense: record.type.indexOf('expense') !== -1,
          }"
        >
          {{ record.type.indexOf('income') !== -1 ? '+' : '-' }}
          {{ getPriceText(record.currency, record.coins) }}
        </span>
      </div>

      <load-more-button
        :busy="busy"
        :has-more="hasMore"
        :trigger="fetch"
      ></load-more-button>
    </div>
  </div>
</template>

<script>
//@ts-check
import api from '@/api/bill'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import { useToast } from '@/composables/toast'
import { convertServerPriceToClientPrice } from '@/utils'
import { onMounted, ref } from 'vue'
import UserAvatar from './UserAvatar.vue'

export default {
  setup() {
    const page = ref(1)
    const limit = ref(10)
    const busy = ref(false)
    const hasMore = ref(true)
    const records = ref([])
    const { showToast } = useToast()

    const fetch = async () => {
      try {
        busy.value = true
        const _records = await api.fetchCoinsRecords(page.value, limit.value)

        if (page.value === 1) {
          records.value = _records
        } else {
          records.value = [...records.value, ..._records]
        }

        if (_records.length < limit.value) {
          hasMore.value = false
        } else {
          page.value += 1
        }
      } catch (err) {
        busy.value = false
        showToast({
          message: err.message,
        })
      } finally {
        busy.value = false
      }
    }

    const getPriceText = function (currency, amount) {
      return `${currency} ${convertServerPriceToClientPrice(amount, currency)}`
    }

    onMounted(() => {
      fetch()
    })

    return {
      getPriceText,
      records,
      busy,
      hasMore,
      fetch,
    }
  },
  components: {
    LoadMoreButton,
    UserAvatar,
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.records {
  margin-top: 1em;
}

.record {
  background: white;
  margin-bottom: 0.5em;
  padding: 0.5em;
  height: 3.6em;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-bottom: solid @border 2px;

  @media @phone {
    height: 5em;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  span {
    display: inline-block;
  }

  .time {
    color: #aaa;
    display: flex;
  }

  .coins {
    text-align: right;
    margin-left: auto;
    font-weight: bold;
    font-size: 1.3em;
    white-space: nowrap;
  }

  .income {
    color: rgb(76, 217, 100);
  }

  .expense {
    color: rgb(255, 45, 85);
  }
}
</style>
