import { isOnMobile } from '@/utils'
import { clientId, clientSecret } from '../config'
import { api } from './base'

export const fetchConfig = async function () {
  try {
    const res = await api.get('/configs')
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getProcessors = async function () {
  let lastError

  for (let attempt = 0; attempt < 10; attempt++) {
    try {
      const res = await api.get('/configs/processors')
      return res.data.result
    } catch (err) {
      console.error(`Attempt ${attempt + 1} failed:`, err)
      lastError = err

      if (attempt < 9) {
        await new Promise((resolve) => setTimeout(resolve, 500))
      }
    }
  }

  throw lastError
}

export const sendSMS = async function (phoneNumber, rawPhoneNumber) {
  try {
    const res = await api.post('/users/register/phone', {
      phoneNumber,
      rawPhoneNumber,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const registerEmail = async function (email, password) {
  try {
    const res = await api.post('/users/register/email', {
      email,
      password,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const loginEmail = async function (email, code) {
  try {
    const res = await api.post('/users/login/email', {
      email,
      code,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const loginPassword = async function (email, password) {
  try {
    const res = await api.post('/users/login/password', {
      email,
      password,
    })
    return res.data.result
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default {
  async verify(phoneNumber, rawPhoneNumber, captcha) {
    try {
      const res = await api.post('/oauth2/code', {
        phoneNumber,
        rawPhoneNumber,
        responseType: 'code',
        captcha,
        clientId: isOnMobile() ? clientId.mobile : clientId.desktop,
      })
      return res.data.result.code
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async getAccessToken(code) {
    try {
      const res = await api.post('/oauth2/access_token', {
        clientId: isOnMobile() ? clientId.mobile : clientId.desktop,
        clientSecret: isOnMobile() ? clientSecret.mobile : clientSecret.desktop,
        grantType: 'authorization_code',
        code,
      })

      const data = res.data
      if (data.code === 200) {
        return {
          accessToken: data.result.accessToken,
          refreshToken: data.result.refreshToken,
          expiredDate: data.result.expiredDate,
        }
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },

  async fetchConfig() {
    try {
      const res = await api.get('/configs')

      const data = res.data
      if (data.code === 200) {
        return data.result
      } else {
        throw new Error(data.message)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },
}
