//@ts-check
import { sendIssue } from '@/api/issue'
import { APP_URL } from '@/config'
import { useApplication } from '@/pinia/application'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from './toast'
import { useUserInfo } from './user_info'

export const currencyOptions = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'CNY',
    value: 'CNY',
  },
  {
    label: 'JPY',
    value: 'JPY',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
]

export const useIssueCreate = function (assigneeRef = null) {
  const assigneeId = useRoute().params.userId
  const currency = ref('USD')
  const price = ref(0)
  const content = ref('')
  const publicIssue = ref(true)
  const router = useRouter()
  const { showToast } = useToast()
  const { t } = useI18n()

  if (!assigneeRef) {
    const composition = useUserInfo(assigneeId)
    assigneeRef = composition.user
  }
  const application = useApplication()

  const priceCent = computed(() => {
    if (currency.value === 'JPY') {
      return Math.round(price.value)
    }

    return Math.round(price.value * 100)
  })

  const minPrice = computed(() => {
    if (!assigneeRef.value) {
      return 0
    }

    return convertServerPriceToClientPrice(
      assigneeRef.value.issue.minPrices[currency.value],
      currency.value,
    )
  })

  const bargainPriceText = computed(() => {
    if (typeof price.value !== 'number') {
      return null
    }
    if (!assigneeRef.value) {
      return null
    }

    return `${currency.value} ${convertServerPriceToClientPrice(assigneeRef.value.issue.minPrices[currency.value], currency.value)}`
  })

  const resolvePriceText = computed(() => {
    if (typeof price.value !== 'number') {
      return null
    }
    if (!assigneeRef.value) {
      return null
    }
    if (currency.value === 'JPY') {
      return `${price.value - assigneeRef.value.issue.minPrices[currency.value]} ${currency.value}`
    }
    return `${((Math.round(price.value * 100) - assigneeRef.value.issue.minPrices[currency.value]) / 100).toFixed(2)} ${currency.value}`
  })

  watch(assigneeRef, () => {
    if (assigneeRef.value && assigneeRef.value.issue) {
      content.value = assigneeRef.value.issue.template
    }
  })

  const assign = async function () {
    try {
      if (price.value <= 0) {
        throw new Error('Price must be greater than 0')
      }
      if (price.value < minPrice.value) {
        throw new Error(
          t('issuePriceNotEnough', {
            currency: currency.value,
            price: minPrice.value,
          }).toString(),
        )
      }

      if (minPrice.value > 0) {
        application.openPaymentPicker({
          processors: ['order2faka', 'wechat', 'alipay'],
          issue: {
            assigneeId,
            content: content.value,
            price: priceCent.value,
            currency: currency.value,
            publicIssue: publicIssue.value,
          },
        })
      } else {
        const dummyInfo = {
          processor: 'order2faka',
          subProcessor: 'paypal',
          returnUrl: `${APP_URL}/users/${assigneeId}/commissions?`,
        }
        if (currency.value === 'CNY') {
          dummyInfo.processor = 'alipay'
          dummyInfo.subProcessor = null
        }
        await sendIssue(
          {
            assigneeId,
            content: content.value,
            price: priceCent.value,
            currency: currency.value,
            publicIssue: publicIssue.value,
          },
          dummyInfo,
        )
        router.push(`/users/${assigneeId}/commissions`)
      }
    } catch (err) {
      showToast(err)
    }
  }

  return {
    currency,
    price,
    assign,
    content,
    publicIssue,
    currencies: currencyOptions,
    bargainPriceText,
    resolvePriceText,
    minPrice,
  }
}
