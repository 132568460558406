export var Currency;
(function (Currency) {
    Currency["JPY"] = "JPY";
    Currency["CNY"] = "CNY";
    Currency["USD"] = "USD";
    Currency["EUR"] = "EUR";
})(Currency || (Currency = {}));
export var CoinsRecordType;
(function (CoinsRecordType) {
    CoinsRecordType["income.revenue"] = "income.revenue";
    CoinsRecordType["income.charge"] = "income.charge";
    CoinsRecordType["income.reward"] = "income.reward";
    CoinsRecordType["income.refund"] = "income.refund";
    CoinsRecordType["income.revenue.group"] = "income.revenue.group";
    CoinsRecordType["income.issue_bargain"] = "income.issue_bargain";
    CoinsRecordType["income.issue"] = "income.issue";
    CoinsRecordType["income.ask"] = "income.ask";
    CoinsRecordType["income.ask_peep"] = "income.ask_peep";
    CoinsRecordType["expense.buy_post"] = "expense.buy_post";
    CoinsRecordType["expense.buy_vip"] = "expense.buy_vip";
    CoinsRecordType["expense.buy_rank"] = "expense.buy_rank";
    CoinsRecordType["expense.refund"] = "expense.refund";
    CoinsRecordType["expense.withdraw"] = "expense.withdraw";
    CoinsRecordType["expense.join_group"] = "expense.join_group";
    CoinsRecordType["expense.open_issue"] = "expense.open_issue";
    CoinsRecordType["expense.resolve_issue"] = "expense.resolve_issue";
    CoinsRecordType["expense.ask"] = "expense.ask";
    CoinsRecordType["expense.ask_peep"] = "expense.ask_peep";
})(CoinsRecordType || (CoinsRecordType = {}));
export var CoinsReceiptType;
(function (CoinsReceiptType) {
    CoinsReceiptType["order"] = "order";
    CoinsReceiptType["withdraw"] = "withdraw";
    CoinsReceiptType["dm"] = "dm";
})(CoinsReceiptType || (CoinsReceiptType = {}));
export var IssueRecordStatus;
(function (IssueRecordStatus) {
    IssueRecordStatus["waitingForPayment"] = "waiting-for-payment";
    IssueRecordStatus["open"] = "open";
    IssueRecordStatus["working"] = "working";
    IssueRecordStatus["reviewing"] = "reviewing";
    IssueRecordStatus["assignerRejected"] = "assigner-rejected";
    IssueRecordStatus["assigneeRejected"] = "assignee-rejected";
    IssueRecordStatus["waitingForResolve"] = "waiting-for-resolve";
    IssueRecordStatus["resolved"] = "resolved";
    IssueRecordStatus["closed"] = "closed";
})(IssueRecordStatus || (IssueRecordStatus = {}));
export var OrderType;
(function (OrderType) {
    OrderType["browse"] = "browse";
    OrderType["circle"] = "circle";
    OrderType["issueBargain"] = "issue-bargain";
    OrderType["issue"] = "issue";
    OrderType["ask"] = "ask";
    OrderType["askPeep"] = "ask-peep";
})(OrderType || (OrderType = {}));
export var AskStatus;
(function (AskStatus) {
    AskStatus["PendingPayment"] = "pending-payment";
    AskStatus["PendingAnswer"] = "pending-answer";
    AskStatus["Answered"] = "answered";
    AskStatus["Deleted"] = "deleted";
})(AskStatus || (AskStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["paid"] = "paid";
    OrderStatus["created"] = "created";
})(OrderStatus || (OrderStatus = {}));
export var OrderProcessor;
(function (OrderProcessor) {
    OrderProcessor["stripe"] = "stripe";
    OrderProcessor["alipay"] = "alipay";
    OrderProcessor["wechat"] = "wechat";
    OrderProcessor["order2faka"] = "order2faka";
    OrderProcessor["admin"] = "admin";
})(OrderProcessor || (OrderProcessor = {}));
export var ArtworkAccess;
(function (ArtworkAccess) {
    ArtworkAccess["full"] = "full";
    ArtworkAccess["preview"] = "preview";
    ArtworkAccess["none"] = "none";
})(ArtworkAccess || (ArtworkAccess = {}));
export var MediaType;
(function (MediaType) {
    MediaType[MediaType["image"] = 0] = "image";
    MediaType[MediaType["video"] = 1] = "video";
    MediaType[MediaType["file"] = 2] = "file";
    MediaType[MediaType["text"] = 3] = "text";
    MediaType[MediaType["obj"] = 4] = "obj";
})(MediaType || (MediaType = {}));
export var DiscountCodeStatus;
(function (DiscountCodeStatus) {
    DiscountCodeStatus["active"] = "active";
    DiscountCodeStatus["deleted"] = "deleted";
    DiscountCodeStatus["disabled"] = "disabled";
})(DiscountCodeStatus || (DiscountCodeStatus = {}));
