<template>
  <div class="posts">
    <post-list :posts="posts"> </post-list>

    <load-more-button
      v-show="posts.length > 0"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search()"
    >
    </load-more-button>

    <pr-empty
      v-if="posts.length === 0 && !hasMore"
      :text="$t('noPostsFound')"
    >
    </pr-empty>
  </div>
</template>

<script>
//@ts-check
import { searchPosts as apiSearchPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import PrEmpty from '@/components/PREmpty.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { onMounted, ref } from 'vue'

export default {
  props: {
    keyword: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const page = ref(1)
    const posts = ref([])
    const hasMore = ref(true)
    const busy = ref(false)
    const { showToast } = useToast()

    const search = async () => {
      try {
        busy.value = true
        const posts = await apiSearchPosts(
          props.keyword,
          5,
          page.value,
          props.userId,
        )
        if (page.value === 1) {
          posts.value = posts
        } else if (posts.length > 0) {
          posts.value = [...posts.value, ...posts]
        }

        if (posts.length === 0) {
          hasMore.value = false
        }

        page.value = page.value + 1
      } catch (err) {
        showToast(err)
      } finally {
        busy.value = false
      }
    }

    onMounted(() => {
      if (props.keyword && posts.value.length === 0) {
        search()
      }
    })

    return {
      page,
      posts,
      hasMore,
      busy,
      ...useResponsive(),
      search,
    }
  },
  components: {
    LoadMoreButton,
    PostList,
    PrEmpty,
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.spinner {
  display: block;
  width: 40px;
  margin: 20px auto;
}
</style>
