//@ts-check
import postapi from '@/api/post'
import { computed, ref } from 'vue'
// @ts-ignore

import { createZipTask } from '@/api/ziptask'
import { useArtworkPurchase } from '@/composables/artwork_purchase'
import { useCurrentUser } from '@/composables/user_data'
import { API_URL, APP_URL } from '@/config'
import { useApplication } from '@/pinia/application'
import { useArtworkStore } from '@/pinia/artwork'
import { ACCESSTOKEN_KEY } from '@/pinia/authenticator'
import { MediaType } from '@perohub/libpero/dist/types'
import localforage from 'localforage'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useArtworkCircle } from './artwork_circle'
import { useConfirm } from './confirm'
import { useToast } from './toast'

export const useArtworkActions = function (postId) {
  const collectorShow = ref(false)
  const artworkStore = useArtworkStore()
  const { buyPost } = useArtworkPurchase(postId)
  const { openSocialSharing, showLogin } = useApplication()
  const { authenticated } = useCurrentUser()
  const application = useApplication()
  const { Toast, showToastWithButtons } = useToast()
  const { circle } = useArtworkCircle(postId)
  const router = useRouter()
  const { confirm } = useConfirm()
  const { t } = useI18n()

  const post = computed(() => {
    return artworkStore.artworks[postId]
  })

  const videoIncluded = computed(() => {
    if (!post.value) {
      return false
    }

    return post.value.pictures.filter((picture) => picture.type === MediaType.video).length > 0
  })

  const browsableMedias = computed(() => {
    if (!post.value) {
      return []
    }

    return post.value.pictures.filter((media) => {
      if (media.type === MediaType.file) {
        return false
      }

      return true
    })
  })

  const intentStar = function () {
    if (!post.value) {
      return
    }

    if (!authenticated.value) {
      return showLogin()
    }

    if (post.value.price > 0) {
      if (post.value.requiresCircleRight) {
        if (!circle.value.isMember && !post.value.paid) {
          return router.push(`/circles/${circle.value._id}`)
        }
      } else if (!post.value.paid) {
        return unlock()
      }
    }

    collectorShow.value = true
  }

  const like = async function () {
    try {
      if (!authenticated.value) {
        return showLogin()
      }

      if (post.value.liked) {
        return
      }

      await postapi.like(postId)
      artworkStore.like(postId)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
    }
  }

  const share = async function () {
    try {
      const url = `${APP_URL}/posts/${postId}`
      openSocialSharing(post.value.title, url)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const unlock = function () {
    if (circle.value) {
      if (post.value.requiresCircleRight) {
        return router.push(`/circles/${circle.value._id}`)
      } else {
        return confirm({
          message: t('unlockCirclePrompt', {
            title: circle.value.title,
          }),
          accept() {
            buyPost({ postId })
          },
          reject() {
            router.push(`/circles/${circle.value._id}`)
          },
          rejectLabel: t('unlockCirclePromptAccept'),
          acceptLabel: t('unlockCirclePromptReject'),
        })
      }
    }

    return buyPost({ postId })
  }

  const viewFromGrid = function (index, gridMedias) {
    application.openBrowser(gridMedias, index, post.value._id)
  }

  const view = function (clickIndex) {
    const media = post.value.pictures[clickIndex]
    const index = browsableMedias.value.indexOf(media)

    if (index === -1) {
      Toast('this media is not browsable')
      return
    }

    if (circle.value) {
      if (post.value.requiresCircleRight) {
        if (circle.value.isMember || post.value.paid) {
          return application.openBrowser(browsableMedias.value, index, post.value._id)
        } else {
          return router.push(`/circles/${circle.value._id}`)
        }
      } else {
        return application.openBrowser(browsableMedias.value, index, post.value._id)

        // if (post.value.paid || post.value.price === 0) {
        //   if (media.type === MediaType.video) {
        //     return false
        //   }

        //   return application.openBrowser(browsableMedias.value, index, post.value._id)
        // }

        // return confirm({
        //   message: t('unlockCirclePrompt', {
        //     title: circle.value.title,
        //   }),
        //   accept() {
        //     buyPost({ postId })
        //   },
        //   reject() {
        //     router.push(`/circles/${circle.value._id}`)
        //   },
        //   rejectLabel: t('unlockCirclePromptAccept'),
        //   acceptLabel: t('unlockCirclePromptReject'),
        // })
      }
    }

    if (post.value.price !== 0) {
      if (!post.value.paid && videoIncluded.value) {
        return buyPost({ postId })
      }

      if (!post.value.paid && !post.value.pictures[index].canBrowse) {
        return buyPost({ postId })
      }
    }

    if (media.type === MediaType.video) {
      return false
    }

    if (media.type === MediaType.obj) {
      return router.push(`/posts/${postId}`)
    }

    application.openBrowser(browsableMedias.value, index, post.value._id)
  }

  const downloadZip = async function () {
    try {
      if (!authenticated.value) {
        return Toast('please login', 'info')
      }

      if (post.value.prices['USD'] !== 0 && !post.value.paid) {
        return buyPost({ postId })
      }

      await createZipTask(postId)

      const close = showToastWithButtons({
        message: t('downloadCreated'),
        confirmLabel: t('goToDownloads'),
        rejectLabel: t('cancel'),
        onReject() {
          close()
        },
        onConfirm() {
          router.push('/i/downloads')
        },
        type: 'ok',
      })
    } catch (err) {
      Toast(err.message)
    } finally {
    }
  }

  const downloadRawFile = async function (mediaId) {
    if (post.value.price !== 0 && !post.value.paid) {
      return buyPost({ postId })
    }

    const url = new URL(`/medias/${mediaId}/download`, API_URL)
    const token = await localforage.getItem(ACCESSTOKEN_KEY)
    url.searchParams.append('token', token)
    const newWindow = window.open(url.toString())
    if (!newWindow) {
      location.href = url.toString()
    }
  }

  const onStarCompleted = function () {
    artworkStore.star(postId)
  }

  return {
    intentStar,
    like,
    collectorShow,
    share,
    view,
    onStarCompleted,
    downloadZip,
    downloadRawFile,
    unlock,
    viewFromGrid,
    browsableMedias,
  }
}
