//@ts-check
import { exchange } from '@/api/exchange'
import { release } from '@/api/post'
import { makeTextMedia, useArtworkWrite } from '@/composables/artwork_write'
import { useCircleStore } from '@/pinia/circle'
import { convertClientPricesToServerPrices, convertClientPriceToServerPrice } from '@/utils'
import { MediaType } from '@perohub/libpero/dist/types'
import { whenever } from '@vueuse/core'
import { useSortable } from '@vueuse/integrations/useSortable'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useCurrentCurrency } from './currency'
import { useDeliverIssues } from './issue_deliver'
import { useToast } from './toast'
import { useCurrentUser } from './user_data'

export const useArtworkPublish = function ({ previews, uploadMedias, uploading }) {
  const { currency } = useCurrentCurrency()
  const countdownable = ref(false)
  const collectible = ref(true)
  const title = ref('')
  const content = ref('')
  const channelIds = ref([])
  const coworkerIds = ref([])
  const priceInfo = ref({
    defaultCurrency: currency.value,
    prices: {
      CNY: 0,
      JPY: 0,
      USD: 0,
      EUR: 0,
    },
    price: 0,
  })
  const timeString = ref('00:00')
  const timePickerRef = ref(null)
  const timeoutMinites = ref(-1)
  const countdownTextRef = ref(null)
  const { currentUser } = useCurrentUser()
  const original = ref(false)
  const useWatermark = ref(false)
  const router = useRouter()
  const { t } = useI18n()
  const originalOptions = ref([
    { name: t('reference'), value: false },
    { name: t('original'), value: true },
  ])
  const originalOption = ref({ name: t('pleaseChoose'), value: null })
  const reference = ref('')
  const showReferenceModal = ref(false)
  const circleStore = useCircleStore()
  const circleOption = ref({ name: t('dontAddToCircle'), value: null })
  const type = ref('album')
  const { Toast } = useToast()
  const isR18 = ref(false)
  const watermarkPositions = ref([])
  const showWatermarkPositionsPicker = ref(false)
  const selfTagIds = ref([])
  const requiresCircleRight = ref(false)
  const canDownload = ref(true)
  const { textMedias, concludeTextMedias, articleMedias, addTextMedia } = useArtworkWrite({ mediasRef: previews })
  const issueId = ref(null)
  const { openedIssues, addIssueRecord } = useDeliverIssues()
  const cocreators = ref([])
  const mediasDomRef = ref(null)
  const publishing = ref(false)
  const videoDefaultWatermark = ref(true)

  let clickTipShowed = false

  const issueOptions = computed(() => {
    return [
      {
        label: t('pleaseChoose'),
        value: null,
      },
      ...openedIssues.value.map((issue) => {
        return {
          label: issue.content,
          value: issue._id,
        }
      }),
    ]
  })

  const circleOptions = computed(() => {
    const options = [
      {
        name: t('dontAddToCircle'),
        value: null,
      },
    ]

    options.push(
      ...circleStore.manageableCircles.map((circle) => {
        return {
          name: circle.title,
          value: circle._id,
        }
      }),
    )

    return options
  })

  const circleIds = computed(() => {
    if (circleOption.value.value) {
      return [circleOption.value.value]
    }

    return []
  })

  const countdownText = computed(() => {
    if (countdownable.value) {
      return t('autoDeleteString', { timeString: timeString.value })
    } else {
      return t('autoDelete')
    }
  })

  const videoMediasCount = computed(() => {
    return previews.value.filter((preview) => preview.type === MediaType.video).length
  })

  watch(issueId, async () => {
    if (!issueId.value) {
      return
    }

    try {
      const issue = openedIssues.value.find((issue) => issue._id === issueId.value)
      priceInfo.value.price = issue.price
      priceInfo.value.defaultCurrency = issue.currency
      priceInfo.value.prices[issue.currency] = issue.price
      const rates = await exchange(issue.currency)
      const rate = rates[issue.currency]
      if (issue.currency === 'JPY') {
        priceInfo.value.prices[issue.currency] = Math.round(priceInfo.value.prices[issue.currency] / rate)
      } else {
        priceInfo.value.prices[issue.currency] = (priceInfo.value.prices[issue.currency] / rate).toFixed(2)
      }
    } catch (err) {
      Toast(err.message)
    }
  })

  watch(countdownable, () => {
    if (!countdownable.value) {
      timeoutMinites.value = -1
    } else {
      timePickerRef.value.open()
    }
  })

  watch(priceInfo, () => {
    for (const preview of previews.value.values()) {
      if (priceInfo.value.price > 0) {
        preview.canBrowse = false
      } else {
        preview.canBrowse = true
      }
    }

    if (priceInfo.value.price > 0) {
      canDownload.value = true
    } else if (type.value === '3dasset') {
      canDownload.value = false
    }

    showClickTipIfNeeded()
  })

  watch(originalOption, () => {
    if (originalOption.value.value !== null) {
      original.value = originalOption.value.value
      if (!original.value) {
        showReferenceModal.value = true
      }
    }
  })

  watch(original, () => {
    if (!original.value) {
      priceInfo.value.price = 0
      for (const currency of Object.keys(priceInfo.value.prices)) {
        priceInfo.value.prices[currency] = 0
      }
    }
  })

  whenever(videoDefaultWatermark, () => {
    useWatermark.value = false
    watermarkPositions.value = []
  })

  whenever(useWatermark, () => {
    videoDefaultWatermark.value = false
  })

  watch(type, () => {
    if (type.value === 'album' || type.value === 'comics') {
      textMedias.value.length = 0
    } else if (type.value === 'article' && textMedias.value.length === 0) {
      textMedias.value.push(makeTextMedia())
    }

    if (type.value === '3dasset' && priceInfo.value.price === 0) {
      canDownload.value = false
    } else {
      canDownload.value = true
    }
  })

  watch(previews.value, () => {
    showClickTipIfNeeded()
  })

  watch(useWatermark, () => {
    showWatermarkPositionsPicker.value = useWatermark.value
  })

  const showClickTipIfNeeded = function () {
    if (clickTipShowed) {
      return
    }

    if (previews.value.length === 0) {
      return
    }

    if (priceInfo.value.prices[priceInfo.value.defaultCurrency] <= 0) {
      return
    }

    Toast(t('selectFreeCoverTip'), 'info')
    clickTipShowed = true
  }

  const closeReferenceModal = function () {
    showReferenceModal.value = false
    if (reference.value === '') {
      originalOption.value = {
        name: t('pleaseChoose'),
        value: null,
      }
    }
  }

  const toggleCanBrowse = function (index) {
    if (priceInfo.value.price <= 0) {
      return
    }

    const preview = previews.value[index]
    preview.canBrowse = !preview.canBrowse
  }

  const onTimePickerConfirmed = function (value) {
    const components = value.split(':').map(Number)
    const [hours, minutes] = components
    timeoutMinites.value = hours * 60 + minutes
  }

  const onTimerClicked = function (event) {
    if (!countdownable.value) {
      return
    }

    event.preventDefault()

    timePickerRef.value.open()
  }

  const publish = async function () {
    try {
      if (uploading.value) {
        return Toast('please wait files to be uploaded', 'info')
      }

      if (originalOption.value.value === null) {
        throw new Error('please select a copyright')
      }

      if (!original.value && reference.value === '') {
        throw new Error('you must specify your reference')
      }

      const concludedTextMedias = concludeTextMedias()

      if (type.value === 'article' && concludedTextMedias.length === 0) {
        throw new Error('please add at lease one text block')
      }

      const getMedias = async function () {
        if (type.value === 'article') {
          const multiMedias = await uploadMedias()
          const arr = []
          for (const media of articleMedias.value.values()) {
            if (media.type === MediaType.text) {
              arr.push(media)
            } else {
              arr.push(multiMedias.shift())
            }
          }
          return arr
        } else if (type.value === 'comics') {
          return await uploadMedias()
        } else if (type.value === 'album') {
          return [...concludedTextMedias, ...(await uploadMedias())]
        } else if (type.value === '3dasset') {
          return await uploadMedias()
        }

        throw new Error('invalid type')
      }

      publishing.value = true

      const medias = await getMedias()

      const prices = convertClientPricesToServerPrices(priceInfo.value.prices)

      const data = {
        title: title.value,
        content: content.value,
        defaultCurrency: priceInfo.value.defaultCurrency,
        price: convertClientPriceToServerPrice(priceInfo.value.price, priceInfo.value.defaultCurrency),
        // @ts-ignore
        userId: currentUser.value.id,
        original: original.value,
        category: 0,
        pictures: medias,
        canDownload: canDownload.value,
        downloadPrice: 0,
        useWatermark: useWatermark.value,
        watermarkPositions: watermarkPositions.value.sort(),
        type: type.value,
        prices,
        collectible: collectible.value,
        groupIds: circleIds.value,
        reference: reference.value,
        isR18: isR18.value,
        selfTagIds: selfTagIds.value,
        requiresCircleRight: requiresCircleRight.value,
        jointUsers: cocreators.value.length > 0 ? cocreators.value : undefined,
        videoDefaultWatermark: videoDefaultWatermark.value,
      }

      const post = await release(data)
      if (issueId.value) {
        await addIssueRecord(issueId.value, {
          content: `I've just delivered artwork ${post.title} to you, please check`,
          status: 'reviewing',
          artworkId: post._id,
        })
      }

      if (type.value === 'album') {
        router.push(`/posts/${post._id}`)
      } else if (type.value === 'comics') {
        router.push(`/comics/${post._id}`)
      } else if (type.value === 'article') {
        router.push(`/posts/${post._id}`)
      } else if (type.value === '3dasset') {
        router.push(`/posts/${post._id}`)
      }
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      publishing.value = false
    }
  }

  useSortable(mediasDomRef, previews, {
    filter: '.add-media',
    // do nothing
    onUpdate: () => {},
  })

  return {
    coworkerIds,
    channelIds,
    circleIds,
    collectible,
    countdownable,
    title,
    content,
    priceInfo,
    countdownText,
    onTimePickerConfirmed,
    timePickerRef,
    timeString,
    onTimerClicked,
    countdownTextRef,
    publish,
    original,
    useWatermark,
    toggleCanBrowse,
    originalOptions,
    originalOption,
    showReferenceModal,
    closeReferenceModal,
    reference,
    circleOptions,
    circleOption,
    type,
    showClickTipIfNeeded,
    isR18,
    watermarkPositions,
    showWatermarkPositionsPicker,
    selfTagIds,
    canDownload,
    requiresCircleRight,
    articleMedias,
    addTextMedia,
    textMedias,
    issueId,
    issueOptions,
    cocreators,
    mediasDomRef,
    publishing,
    videoDefaultWatermark,
    videoMediasCount,
  }
}
