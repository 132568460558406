<template>
  <div style="width: 100%">
    <progress-bar
      v-show="warmupStatus === 'processing'"
      style="margin-top: 1rem"
      :mode="tasks.doneTasks.length === 0 ? 'indeterminate' : 'determinate'"
      :value="(tasks.doneTasks.length / tasksTotal) * 100"
    >
      {{ tasks.doneTasks.length }} / {{ tasksTotal }}
    </progress-bar>
    <div
      v-if="warmupStatus === 'processing'"
      style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        align-items: center;
        justify-content: flex-end;
      "
    >
      <p>{{ $t('stillProcessing') }}</p>
      <pr-button
        :loading="checking"
        @click="checkAll"
      >
        {{ $t('refresh') }}
      </pr-button>
    </div>
    <div
      v-if="warmupStatus === 'not_inited'"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 1rem;
        align-items: center;
        justify-content: center;
      "
    >
      <p>{{ $t('loading') }}</p>
    </div>
    <div
      v-if="warmupStatus === 'not_started' && needsWarmup"
      style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        height: 10rem;
        justify-content: center;
      "
    >
      <p>{{ $t('needsWarmup', { nickname: post.user.nickname }) }}</p>
      <pr-button
        :loading="initiating"
        @click="initiateArtworkWarmup"
      >
        {{ $t('initiateWarmup') }}
      </pr-button>
    </div>
    <div
      v-if="warmupStatus === 'failed' && needsWarmup"
      style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        height: 10rem;
        justify-content: center;
      "
    >
      <p>
        {{ tasks.pendingTasks.length }}/{{ tasksTotal }}
        {{ $t('fail') }}
      </p>
      <pr-button
        :loading="initiating"
        @click="initiateArtworkWarmup"
      >
        {{ $t('retry') }}
      </pr-button>
    </div>
    <slot
      :warmup-status="warmupStatus"
      :image-status-map="imageStatusMap"
      :video-resolution-status-map="videoResolutionStatusMap"
      :needs-warmup="needsWarmup"
      :browsable="browsable"
      :initiating="initiating"
      :initiate-artwork-warmup="initiateArtworkWarmup"
      :warmup-status-inited="warmupStatusInited"
    >
    </slot>
  </div>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { useArtworkWarmup } from '@/composables/artwork_warmup'
import ProgressBar from 'primevue/progressbar'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      ...useArtworkWarmup({ artworkProp: props.post }),
    }
  },
  components: {
    PrButton,
    ProgressBar,
  },
}
</script>

<style scoped></style>
