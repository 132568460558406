<template>
  <pr-popup
    v-model="visible"
    class="picker">
    <template #header>
      <span>{{ $t('joinCircle', { title: circle.title }) }}</span>
    </template>

    <panel
      v-if="circle.status === 'completed'">
      <span>{{ upfrontPriceText }}</span>
    </panel>
    <panel
      v-else
      :header="$t('contentSinceNow')">
      <span v-if="circle.salesType === 'long-term'">{{ subscribePriceText }}</span>
      <span v-else>{{ subscribePriceText }} {{ $t('tillSerializationOver') }}</span>
    </panel>

    <panel :header="$t('contentInPast')">
      <span style="margin-bottom: 1rem;">{{ complementPriceText }}</span>

      <div class="month-numbers">
        <toggle-button
          v-for="chapter in chapters"
          :key="chapter._id"
          :value="selectedMonthNumbers.includes(chapter.monthNumber)"
          :style="{
            hover: circle.forceComplement ? 'disabled' : 'inherit'
          }"
          :on-label="chapter.groupTitle"
          :off-label="chapter.groupTitle"
          @click="toggleMonthNumber(chapter.monthNumber)">
        </toggle-button>
      </div>

      <load-more-button
        :has-more="chapterHasmore"
        :busy="loading"
        @trigger="getChapters">
      </load-more-button>
    </panel>

    <panel :header="$t('totalAmount')">
      <span>{{ priceText }}</span>
    </panel>

    <template #footer>
      <pr-button
        size="large"
        type="primary"
        :flat="true"
        :disabled="price === 0"
        @click="intentPurchase">
        {{ $t('payment') }}
      </pr-button>
    </template>
  </pr-popup>
</template>

<script>
//@ts-check
import PrButton from '@/components/PRButton.vue'
import { useCirclePurchase } from '@/composables/circle_purchase'
import { useCurrentCurrency } from '@/composables/currency'
import { useResponsive } from '@/composables/responsive'
import { ref, watch } from 'vue'
import Panel from 'primevue/panel'
import ToggleButton from 'primevue/togglebutton'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PrPopup from '@/components/PRPopup.vue'


export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    circle: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    const { currency } = useCurrentCurrency()
    const visible = ref(props.modelValue)

    watch(visible, () => {
      emit('update:modelValue', visible.value)
    })

    watch(props, () => {
      visible.value = props.modelValue
    })

    return {
      ...useCirclePurchase(props.circle, currency),
      currency,
      ...useResponsive(),
      visible,
    }
  },
  components: {
    PrButton,
    Panel,
    ToggleButton,
    LoadMoreButton,
    PrPopup,
  }
}
</script>

<style lang="less">
.picker.p-drawer {
  height: 42.5rem !important;
}
</style>

<style lang="less" scoped>
@import "@/assets/base.less";

.p-panel {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 40rem;

  @media @phone {
    width: 19rem;
    min-width: 100%;
  }
}

.picker {
  overflow-x: hidden;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  button {
    width: 100%;
    display: block;
    margin-top: 1rem;
  }

  .month-numbers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @media @phone {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>