<template>
  <div class="post-list-item-tuchong-container">
    <div v-if="artworkLocales.length > 0" class="translations">
      <div class="locales">
        <div
          v-for="locale in artworkLocales"
          :key="locale"
          class="locale touchable hoverable"
          :class="{
            selected: locale === currentLocale,
          }"
          @click="currentLocale = locale"
        >
          {{ $t(locale) }}
        </div>
      </div>

      <p
        v-show="localizedContents[currentLocale] === artworkTextContent"
        v-linkify:options="{ className: 'postlistitemtuchong-linkified' }"
        class="item-content-text"
        v-html="sanitize(artworkTextContent)"
      ></p>

      <p
        v-show="localizedContents[currentLocale] !== artworkTextContent"
        v-linkify:options="{ className: 'postlistitemtuchong-linkified' }"
        class="item-content-text"
        v-html="sanitize(localizedContents[currentLocale])"
      ></p>

      <p v-if="post.reference">From: {{ post.reference }}</p>
    </div>
    <p
      v-else
      v-linkify:options="{ className: 'postlistitemtuchong-linkified' }"
      class="item-content-text"
      v-html="sanitize(artworkTextContent)"
    ></p>

    <artwork-warmup v-slot="slotProps" :post="post">
      <div v-if="slotProps.browsable" ref="mediaListDom" class="items">
        <div v-for="(item, index) in medias" :key="item._id" class="item">
          <div
            v-if="item.type === MediaType.image"
            class="image"
            :class="{ hoverable: getIsUnlock(post, item) }"
            @click.prevent.stop="
              getIsUnlock(post, item) &&
              ((slotProps.needsWarmup && slotProps.imageStatusMap[item._id]) || !slotProps.needsWarmup) &&
              view(index)
            "
          >
            <transition-image
              v-if="
                ((slotProps.needsWarmup && slotProps.imageStatusMap[item._id]) || !slotProps.needsWarmup) &&
                item.height &&
                item.width
              "
              :src="getIsUnlock(post, item) ? item.url : item.thum"
              :thumbnail="item.thum"
              :styles="
                item.width && item.height
                  ? {
                      aspectRatio: item.width / item.height,
                      width: '100%',
                      objectFit: 'contain',
                      pointerEvents: 'none',
                    }
                  : {
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                      height: 'inherit !important',
                      pointerEvents: 'none',
                    }
              "
              :animated="false"
              @on-dominant-color="(...rgb) => onCoverDominantColor(index)(...rgb)"
              :show-progress-bar="true"
            >
            </transition-image>
            <img
              v-else-if="(slotProps.needsWarmup && slotProps.imageStatusMap[item._id]) || !slotProps.needsWarmup"
              style="width: 100%; object-fit: contain; height: inherit !important; pointer-events: none"
              :src="getIsUnlock(post, item) ? item.url : item.thum"
            />
            <div
              v-else-if="slotProps.needsWarmup && !slotProps.imageStatusMap[item._id] && slotProps.warmupStatusInited"
            >
              <p>{{ $t('watermarkPreparing') }}</p>
            </div>
            <div v-else-if="slotProps.needsWarmup && !slotProps.warmupStatusInited">
              <p>{{ $t('loading') }}</p>
            </div>
            <pr-button
              v-if="!getIsUnlock(post, item)"
              :plain="true"
              :style="
                buttonColors[index] && {
                  borderColor: buttonColors[index],
                  color: buttonColors[index],
                }
              "
              @click.stop.prevent="view(index)"
            >
              {{ post.requiresCircleRight ? priceText : $t('payToUnlock', { price: priceText }) }}
            </pr-button>
          </div>

          <div v-else-if="item.type === MediaType.video" class="video">
            <video-player
              v-if="
                (slotProps.needsWarmup &&
                  slotProps.videoResolutionStatusMap[item._id] &&
                  slotProps.videoResolutionStatusMap[item._id].length > 0) ||
                !slotProps.needsWarmup
              "
              :media="item"
              :available-resolutions="slotProps.videoResolutionStatusMap[item._id]"
              :paid="getIsUnlock(post, item)"
              :artwork-id="post._id"
            >
            </video-player>
            <div v-else-if="slotProps.needsWarmup && slotProps.warmupStatusInited">
              <p>{{ $t('watermarkPreparing') }}</p>
            </div>
            <div v-else-if="slotProps.needsWarmup && !slotProps.warmupStatusInited">
              <p>{{ $t('loading') }}</p>
            </div>
          </div>

          <div v-else-if="item.type === MediaType.file" class="raw">
            <Button
              :label="item.filename"
              icon="pi pi-download"
              class="p-button-lg"
              icon-pos="right"
              @click="downloadRawFile(item._id)"
            >
            </Button>
          </div>

          <div
            v-else-if="item.type === MediaType.text && getIsUnlock(post, item)"
            class="paragraph markdown"
            v-html="sanitize(item.textContent)"
          ></div>

          <div v-else-if="item.type === MediaType.text && !getIsUnlock(post, item)" class="paragraph blur-text">
            <div class="blur-cover">
              <pr-button :plain="true" @click.stop.prevent="unlock">
                {{ post.requiresCircleRight ? priceText : $t('payToUnlock', { price: priceText }) }}
              </pr-button>
            </div>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </div>

          <div v-else-if="item.type === MediaType.obj">
            <model-renderer :media-id="item._id"></model-renderer>
          </div>
        </div>
      </div>
    </artwork-warmup>
  </div>
</template>

<script>
//@ts-check
import ModelRenderer from '@/components/ModelRenderer.vue'
import TransitionImage from '@/components/TransitionImage.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { useArtworkActions } from '@/composables/artwork_action'
import { useArtworkText } from '@/composables/artwork_text'
import { useMediaListButtonColor } from '@/composables/media_list_button_color'
import { useMediaListContent } from '@/composables/media_list_content'
import { useMediaListScroll } from '@/composables/media_list_scroll'
import { useResponsive } from '@/composables/responsive'
import { MediaType } from '@perohub/libpero/dist/types'
import { sanitize } from 'dompurify'
import Button from 'primevue/button'
import ArtworkWarmup from './ArtworkWarmup.vue'
import PrButton from './PRButton.vue'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    priceText: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const artworkActionComposition = useArtworkActions(props.post._id)

    return {
      ...useMediaListContent(props.post),
      ...useMediaListButtonColor(props.post.pictures.filter((media) => media.type === MediaType.image).length),
      ...useMediaListScroll(props.post.pictures, artworkActionComposition.browsableMedias),
      ...useResponsive(),
      ...artworkActionComposition,
      ...useArtworkText(props.post._id),
      MediaType,
    }
  },
  methods: {
    sanitize,
  },
  components: {
    VideoPlayer,
    Button,
    TransitionImage,
    PrButton,
    ModelRenderer,
    ArtworkWarmup,
  },
}
</script>

<style lang="less">
@import '@/assets/base.less';

.translations {
  display: flex;
  flex-direction: column;

  .locales {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    gap: 1rem;

    .locale {
      height: 2rem;
      margin-right: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;

      &.selected {
        color: @main;
        text-decoration: underline;
        font-size: 1.3rem;
      }
    }
  }
}

.post-list-item-tuchong-container {
  padding-bottom: 0.5em;

  .items {
    padding-bottom: 0.5em;
    margin-top: 15px;
    text-align: center;
    z-index: 1;
    position: relative;

    > p {
      font-size: 0.7em;
      margin-top: -1em;
      margin-bottom: 0.6em;
    }

    .paragraph {
      text-align: left;
    }

    .paragraph.blur-text {
      min-height: 6rem;
      position: relative;
    }

    .paragraph .blur-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(0.24rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .paragraph p {
      margin: 1rem 0;
    }

    .item {
      width: 100%;
      margin-bottom: 0.5em;

      .responsive {
        object-fit: contain;
        width: 100%;
        pointer-events: none;
      }

      video.responsive {
        pointer-events: inherit;
      }

      .video-unpaid {
        min-height: 20em;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        .cover {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 0.33);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;

          .play {
            width: 5em;
            height: 5em;
          }
        }
      }

      .image {
        position: relative;
      }

      .raw {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .raw button {
        min-width: min(14rem, 80%);
      }

      .image button,
      .blur-cover button {
        position: absolute;
        width: 150px;
        left: 0;
        right: 0;
        top: 42%;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.8em;
      }
    }
  }

  .item-content {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: inline-block;

    .postlistitemtuchong-linkified {
      color: @blue;
      cursor: pointer;
      opacity: 0.65;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
      float: none;
    }
  }

  .item-content-text {
    margin-left: 1em;
    margin-right: 1em;
    font-size: 0.88em;
    white-space: pre-wrap;

    a {
      color: @blue;
      cursor: pointer;
      opacity: 0.65;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }
    span {
      color: @blue;
      cursor: pointer;
      opacity: 0.65;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }
  }
}
</style>
