//@ts-check
import { addRecord, updateIssue } from '@/api/issue'
import { useApplication } from '@/pinia/application'
import { useArtworkStore } from '@/pinia/artwork'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, ref } from 'vue'
import { useToast } from './toast'

export const useIssueAction = function (issueRef, getIssueFn = null) {
  const actionLoading = ref(false)
  const commenting = ref(false)
  const comment = ref('')
  const { Toast } = useToast()
  const artworkId = ref(null)
  const showArtworkSelector = ref(false)
  const artworkStore = useArtworkStore()
  const application = useApplication()

  const resolvePriceText = computed(() => {
    if (!issueRef.value) {
      return null
    }

    const price =
      issueRef.value.price -
      issueRef.value.assignee.issue.minPrices[issueRef.value.currency]
    return `${convertServerPriceToClientPrice(price, issueRef.value.currency)} ${issueRef.value.currency}`
  })

  const selectedArtwork = computed(() => {
    if (artworkId.value) {
      return artworkStore.artworks[artworkId.value]
    }

    return null
  })

  const changeStatus = async function (status) {
    try {
      actionLoading.value = true
      if (status === 'reviewing') {
        if (!artworkId.value) {
          throw new Error('select artwork first')
        }
      }

      await addRecord(issueRef.value._id, {
        status,
        artworkId: artworkId.value,
      })
      if (getIssueFn) {
        await getIssueFn()
      }
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      actionLoading.value = false
    }
  }

  const sendComment = async function () {
    try {
      if (comment.value.trim() === '') {
        throw new Error('comment is required')
      }
      commenting.value = true
      await addRecord(issueRef.value._id, {
        content: comment.value,
      })
      comment.value = ''
      if (getIssueFn) {
        await getIssueFn()
      }
    } catch (err) {
      console.error(err)
      Toast(err)
    } finally {
      commenting.value = false
    }
  }

  const update = async function (doc) {
    try {
      actionLoading.value = true
      await updateIssue(issueRef.value._id, doc)
      if (getIssueFn) {
        await getIssueFn()
      }
    } catch (err) {
      Toast(err)
    } finally {
      actionLoading.value = false
    }
  }

  const intentToResolve = async function () {
    application.openPaymentPicker({
      processors: ['order2faka', 'wechat', 'alipay'],
      issueResolve: {
        issueId: issueRef.value._id,
      },
    })
  }

  return {
    comment,
    sendComment,
    commenting,
    changeStatus,
    close,
    actionLoading,
    artworkId,
    showArtworkSelector,
    selectedArtwork,
    intentToResolve,
    resolvePriceText,
    update,
  }
}
