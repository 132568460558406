//@ts-check

import { convertServerPriceToClientPrice } from '@/utils'

export const usePriceFormatter = function () {
  return {
    formatPrice(price, currency, handleDecimals = true) {
      if (!handleDecimals) {
        return `${currency} ${price}`
      }

      return `${currency} ${convertServerPriceToClientPrice(price, currency)}`
    },
  }
}
