//@ts-check
import {
  getChapters as getCircleChapters,
  getCirclePosts,
  getCircle as getOne,
} from '@/api/circle'
import { APP_URL } from '@/config'
import { useAuthenticator } from '@/pinia/authenticator'
import {
  convertServerPriceToClientPrice,
  countryCode2Locale,
  eventEmitter,
  seowords,
} from '@/utils'
import { events } from '@/utils/events'
import { useHead } from '@unhead/vue'
import { until, whenever } from '@vueuse/core'
import { sanitize } from 'dompurify'
import { format } from 'timeago.js'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCurrentCountry } from './country'
import { useToast } from './toast'
import { useCurrentUser } from './user_data'

const limit = 10

export const useCirclePresent = function (
  refCurrency,
  {
    circleId: plainCircleId = null,
    circle: plainCircle = null,
    fromNetwork = false,
  } = {},
) {
  const { t, d } = useI18n()
  const route = useRoute()
  const circle = ref(plainCircle ? plainCircle : null)
  let circleId = plainCircleId || route.params.circleId
  if (!circleId && plainCircle) {
    circleId = plainCircle._id
  }
  const loading = ref(false)
  const posts = ref([])
  const chapters = ref([])
  const chapterPage = ref(1)
  const chapterHasmore = ref(true)
  const postPage = ref(1)
  const postHasmore = ref(true)
  const sort = ref('des')
  const threads = ref([])
  const threadPage = ref(1)
  const { authenticated, currentUserAuthed } = useCurrentUser()
  const authenticator = useAuthenticator()
  const { countryCode } = useCurrentCountry()
  const { Toast } = useToast()

  const upfrontPrice = computed(() => {
    if (!circle.value.prices) {
      return -1
    }

    return convertServerPriceToClientPrice(
      circle.value.upfrontPrices[refCurrency.value],
      refCurrency.value,
    )
  })

  const tags = computed(() => {
    const arr = [frequencyText.value]
    if (lastUpdatedAtText.value) {
      arr.push(lastUpdatedAtText.value)
    }
    if (circle.value.status === 'updating') {
      arr.push(t('updating').toString())
    } else if (circle.value.status === 'completed') {
      arr.push(t('completed').toString())
    } else if (circle.value.status === 'preparing') {
      arr.push(t('preparing').toString())
    }
    return arr
  })

  const recurringPrice = computed(() => {
    if (!circle.value.prices) {
      return -1
    }

    return convertServerPriceToClientPrice(
      circle.value.prices[refCurrency.value],
      refCurrency.value,
    )
  })

  const priceText = computed(() => {
    const month = t('month')
    return `${refCurrency.value} ${recurringPrice.value} / ${month}`
  })

  const lastUpdatedAtText = computed(() => {
    if (!circle.value.lastUpdatedAt) {
      return ''
    }

    return format(circle.value.lastUpdatedAt, navigator.language)
  })

  const frequencyText = computed(() => {
    if (!circle.value.updateFrequency) {
      return ''
    }

    const count = circle.value.updateFrequency.count
    const period = t(circle.value.updateFrequency.period)
    const freqency = t('circleFrequency', { period, count })

    return freqency
  })

  const circleMetaInfo = computed(() => {
    const info = {
      meta: [],
    }
    let content = ''

    if (!circle.value) {
      info.title = `${t('circle')} | Perohub`
    } else {
      info.title = `${sanitize(circle.value.title)} | Perohub`
      content = `${sanitize(circle.value.title)} ${sanitize(circle.value.description)}`
      info.meta.push(
        {
          name: 'keywords',
          content: `${sanitize(circle.value.title)},${sanitize(circle.value.author.nickname)},${seowords}`,
        },
        {
          name: 'twitter:image',
          content: circle.value.coverUrl,
        },
        {
          name: 'og:image',
          content: circle.value.coverUrl,
        },
        {
          name: 'og:url',
          content: `${APP_URL}/circles/${circle.value._id}`,
        },
      )
    }

    info.meta.push({ name: 'description', content: sanitize(content) })
    info.meta.push(
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:site',
        content: '@perohubcom',
      },
      {
        name: 'twitter:title',
        content: sanitize(info.title),
      },
      {
        name: 'twitter:description',
        content,
      },
      {
        name: 'og:title',
        content: sanitize(info.title),
      },
    )

    return info
  })

  if (route.params.circleId) {
    useHead(circleMetaInfo)
  }

  watch(authenticated, () => {
    if (authenticated.value && circleId) {
      getCircle()
    }
  })

  const censorshipForChina = function () {
    if (
      circle.value.author.isR18 &&
      countryCode.value === 'CN' &&
      !currentUserAuthed.value
    ) {
      circle.value.coverUrl =
        'https://placehold.co/256x256?text=Not%20Available%20In%20Your%20Region'
      circle.value.lastUpdatedPost.title = t('outofservice', {
        countryCode: countryCode.value,
      })
    }
  }

  if (circle.value) {
    censorshipForChina()
  } else {
    whenever(circle, () => {
      censorshipForChina()
    })
  }

  const getCircle = async function () {
    try {
      await authenticator.checkAuth()
      circle.value = await getOne(circleId)
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
    }
  }

  const getPosts = async function (page, limit, sort) {
    try {
      loading.value = true
      const newPosts = await getCirclePosts(circleId, page, limit, sort)
      if (newPosts.length === 0) {
        postHasmore.value = false
      } else {
        posts.value = [...posts.value, ...newPosts]
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const getChapters = async function () {
    try {
      loading.value = true
      const newChapters = await getCircleChapters(circleId, chapterPage.value)
      if (newChapters.length === 0) {
        chapterHasmore.value = false
      } else {
        chapterPage.value += 1
        chapters.value.push(...newChapters)
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const onSortUpdated = async function (sortValue) {
    if (sort.value === sortValue) {
      return
    }
    sort.value = sortValue
    try {
      postPage.value = 1
      posts.value = []
      postHasmore.value = true
      await getPosts(postPage.value, limit, sort.value)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const loadMorePosts = async function () {
    const count = posts.value.length
    postPage.value += 1
    console.log('load more posts')
    await getPosts(postPage.value, limit, sort.value)
    const countAfter = posts.value.length
    if (countAfter - count < limit) {
      postHasmore.value = false
    }
  }

  const initContent = async function () {
    postPage.value = 1
    posts.value = []

    await until(circle).toMatch((v) => v !== null)

    if (circle.value.displayType === 'album') {
      await getChapters()
      await getPosts(postPage.value)
    } else if (circle.value.displayType === 'comics') {
      await getPosts(postPage.value, limit, sort.value)
    }
  }

  const getPostTitle = function (post) {
    let title = ''
    if (!post) {
      return ''
    }

    if (!post.titleLocales) {
      title = post.title
    } else {
      const locale = countryCode2Locale(countryCode.value)
      if (!post.titleLocales[locale]) {
        title = post.title
      } else {
        title = post.titleLocales[locale]
      }
    }

    if (!title && post.createdAt) {
      return t('artworkOnDate', { date: d(post.createdAt) })
    }

    return title
  }

  eventEmitter.on(events.LoginCompleted, initContent)
  eventEmitter.on(events.LogoutCompleted, initContent)

  onMounted(async () => {
    if (!circleId) {
      return
    }

    if (!circle.value || fromNetwork) {
      await getCircle()
    }

    if (fromNetwork) {
      initContent()
    }
  })

  return {
    loading,
    circle,
    priceText,
    recurringPrice,
    upfrontPrice,
    posts,
    postPage,
    chapters,
    threads,
    threadPage,
    onSortUpdated,
    postHasmore,
    loadMorePosts,
    sort,
    tags,
    getChapters,
    chapterHasmore,
    circleMetaInfo,
    getCircle,
    getPostTitle,
  }
}
