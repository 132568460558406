<template>
  <pr-popup v-model="visible" class="media-saver" @hide="visible = false">
    <template #header>
      <span>{{ $t('chooseStarItem') }}</span>
    </template>

    <div class="medias">
      <div v-for="(media, index) in browserableMedias" :key="media._id" class="media touchable" @click="toggle(index)">
        <img
          class="cover"
          :class="{
            'cover-selected': selectedIndices.includes(index),
          }"
          :src="media.thum"
        />
        <img v-if="selectedIndices.includes(index)" class="selected" src="@/assets/ic-page-switch-selected@2x.png" />
        <img v-else class="selected" src="@/assets/ic-page-switch-normal@2x.png" />
      </div>
      <div v-if="browserableMedias.length < 4" class="media placeholder"></div>
    </div>

    <template #footer>
      <pr-button
        style="width: 100%"
        type="primary"
        :loading="loading"
        :disabled="selectedIndices.length === 0"
        @click="confirm"
      >
        {{ $t('confirm') }}({{ selectedIndices.length }})
      </pr-button>
    </template>
  </pr-popup>
</template>

<script>
//@ts-check
import { starMedias } from '@/api/media'
import PrButton from '@/components/PRButton.vue'
import PrPopup from '@/components/PRPopup.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { MediaType } from '@perohub/libpero/dist/types'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'completed'],
  setup(props, { emit }) {
    const browserableMedias = computed(() => {
      return props.post.pictures.filter((media) => media.type === MediaType.image || media.type === MediaType.video)
    })
    const visible = ref(props.modelValue)
    const selectedIndices = ref(browserableMedias.value.map((_, index) => index))
    const { t } = useI18n()
    const { Toast } = useToast()
    const loading = ref(false)

    const selectedIds = computed(() => {
      const ids = browserableMedias.value
        .filter((_, index) => {
          if (selectedIndices.value.includes(index)) {
            return true
          }

          return false
        })
        .map((media) => {
          return media._id
        })

      return ids
    })

    watch(props, () => {
      visible.value = props.modelValue
    })

    watch(visible, () => {
      if (!visible.value) {
        emit('update:modelValue', visible.value)
      }
    })

    const confirm = async function () {
      try {
        loading.value = true
        await starMedias(selectedIds.value)
        emit('update:modelValue', false)
        emit('completed')
        Toast({ message: t('success') }, 'ok')
      } catch (err) {
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }

    const toggle = function (indexValue) {
      if (!selectedIndices.value.includes(indexValue)) {
        selectedIndices.value.push(indexValue)
      } else {
        selectedIndices.value.splice(selectedIndices.value.indexOf(indexValue))
      }
    }

    return {
      visible,
      ...useResponsive(),
      confirm,
      selectedIndices,
      toggle,
      browserableMedias,
      loading,
    }
  },
  components: {
    PrButton,
    PrPopup,
  },
}
</script>

<style>
.media-saver.p-dialog {
  width: 30em;
}

.media-saver.p-drawer {
  height: 40em !important;
}

.media-saver .p-drawer-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>

<style scoped lang="less">
@import '@/assets/base.less';

.medias {
  flex: 1;
  display: grid;
  grid-gap: 0.5em;
  min-height: 5em;
  overflow-y: scroll;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.media {
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: visible;

  &.placeholder {
    visibility: hidden;
  }

  .cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
  }

  .cover-selected {
    filter: brightness(100%);
  }

  .selected {
    height: 2em;
    width: 2em;
    position: absolute;
    top: 0.2em;
    left: 0.2em;
  }
}
</style>
