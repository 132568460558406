//@ts-check
import { convertServerPricesToClientPrices } from '@/utils'
import { whenever } from '@vueuse/core'
import { ref } from 'vue'
import { useCurrentCurrency } from './currency'
import { useCurrentUser } from './user_data'

export const useUserAskEdit = function () {
  const { currentUser, authenticated } = useCurrentUser()
  const { currency } = useCurrentCurrency()
  const availableForAsk = ref(false)
  const askPrices = ref({
    price: 0,
    defaultCurrency: currency.value,
    prices: {
      USD: 0,
      CNY: 0,
      JPY: 0,
      EUR: 0,
    },
  })

  whenever(
    authenticated,
    async () => {
      if (currentUser.value.ask) {
        askPrices.value.defaultCurrency = currency.value
        askPrices.value.prices = convertServerPricesToClientPrices(currentUser.value.ask.prices)
        askPrices.value.price = askPrices.value.prices[askPrices.value.defaultCurrency]
        availableForAsk.value = currentUser.value.ask.available && currentUser.value.authed
      }
    },
    { immediate: true },
  )

  return {
    availableForAsk,
    askPrices,
  }
}
