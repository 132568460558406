//@ts-check
import { exchange } from '@/api/exchange'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, onMounted, ref } from 'vue'
import { useToast } from './toast'
import { useCurrentUser } from './user_data'

export const useUserBalance = function () {
  const { currentUser: user, authenticated } = useCurrentUser()
  const usdExchangeRates = ref({})
  const { Toast } = useToast()

  const balances = computed(() => {
    if (!authenticated.value) {
      return []
    }

    const balances = []
    const raw = user.value.balances
    for (const currency of Object.keys(raw)) {
      if (raw[currency] > 0) {
        balances.push({ currency, amount: raw[currency] })
      }
    }

    if (balances.length === 0) {
      balances.push({ currency: 'USD', amount: 0 })
    }

    return balances
  })

  const totalUSD = computed(() => {
    let total = 0

    // @ts-ignore
    for (const balance of balances.value.values()) {
      if (balance.currency === 'USD') {
        total += balance.amount
        continue
      }

      const rate = usdExchangeRates.value[balance.currency]
      if (isNaN(Number(rate))) {
        return 0
      }

      if (balance.currency === 'JPY') {
        total = total + Math.round(balance.amount / rate) * 100
      } else {
        total += Math.round(balance.amount / rate)
      }
    }

    return total
  })

  const getBalanceText = function ({ currency, amount }) {
    return `${currency} ${convertServerPriceToClientPrice(amount, currency)}`
  }

  onMounted(async () => {
    try {
      usdExchangeRates.value = await exchange('USD', false)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  })

  return {
    balances,
    getBalanceText,
    totalUSD,
  }
}
