//@ts-check
import { getActiveMonthNumbers, getCircle } from '@/api/circle'
// @ts-ignore

import {
  getChapters as getCircleChapters,
  getMonthNumbers as getCircleMonthNumbers,
} from '@/api/circle'
import { useToast } from '@/composables/toast'
import { useApplication } from '@/pinia/application'
import { convertServerPriceToClientPrice } from '@/utils'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCurrentUser } from './user_data'

export const useCirclePurchase = function (plainCircle, currencyRef) {
  const circleId = useRoute().params.circleId
  const circle = ref(plainCircle)
  const route = useRoute()
  const { openPaymentPicker } = useApplication()
  const { t } = useI18n()
  const { Toast } = useToast()
  const selectedMonthNumbers = ref([])
  const chapters = ref([])
  const loading = ref(false)
  const chapterHasmore = ref(true)
  const chapterPage = ref(1)
  const monthNumbers = ref([])
  const activeMonthNumbers = ref([])
  const { authenticated } = useCurrentUser()

  const upfrontPrice = computed(() => {
    if (!circle.value) {
      return -1
    }

    return convertServerPriceToClientPrice(
      circle.value.upfrontPrices[currencyRef.value],
      currencyRef.value,
    )
  })

  const upfrontPriceText = computed(() => {
    return `${currencyRef.value} ${upfrontPrice.value}`
  })

  const complementPrice = computed(() => {
    if (!circle.value) {
      return -1
    }

    return convertServerPriceToClientPrice(
      circle.value.complementPrices[currencyRef.value],
      currencyRef.value,
    )
  })

  const complementPriceText = computed(() => {
    if (!circle.value) {
      return -1
    }

    return `${currencyRef.value} ${complementPrice.value} x ${complementCount.value} ${t('month')} = ${currencyRef.value} ${complementPrice.value * complementCount.value}`
  })

  const subscribePrice = computed(() => {
    if (!circle.value) {
      return 0
    }

    return convertServerPriceToClientPrice(
      circle.value.prices[currencyRef.value],
      currencyRef.value,
    )
  })

  const subscribePriceText = computed(() => {
    return `${currencyRef.value} ${subscribePrice.value} / ${t('month')}`
  })

  const complementCount = computed(() => {
    return Math.max(
      0,
      selectedMonthNumbers.value.length - activeMonthNumbers.value.length,
    )
  })

  const price = computed(() => {
    if (!circle.value) {
      return 0
    }

    if (circle.value.status === 'completed') {
      return upfrontPrice.value
    }

    const currentMonthNumber =
      new Date().getMonth() + new Date().getFullYear() * 12

    if (activeMonthNumbers.value.includes(currentMonthNumber)) {
      return Number((complementPrice.value * complementCount.value).toFixed(2))
    }

    return Number(
      (
        subscribePrice.value +
        complementPrice.value * complementCount.value
      ).toFixed(2),
    )
  })

  const priceText = computed(() => {
    return `${currencyRef.value} ${price.value}`
  })

  const justPaid = computed(() => {
    if (route.query.payment_intent_client_secret || route.query.justPaid) {
      return true
    }
    return false
  })

  watch(circle, () => {
    if (circleId && justPaid.value) {
      checkPaidStatus()
    }

    if (circle.value) {
      selectedMonthNumbers.value = []
      chapterPage.value = 1
      getChapters()
    }
  })

  watch(monthNumbers, () => {
    if (circle.value && circle.value.forceComplement) {
      selectedMonthNumbers.value = monthNumbers.value
    }
  })

  const getChapters = async function () {
    try {
      loading.value = true
      const newChapters = await getCircleChapters(circleId, chapterPage.value)
      if (newChapters.length === 0) {
        chapterHasmore.value = false
      } else {
        const currentMonthNumber =
          new Date().getMonth() + new Date().getFullYear() * 12
        if (chapterPage.value === 1) {
          chapters.value = newChapters.filter(
            (chapter) => chapter.monthNumber !== currentMonthNumber,
          )
        } else {
          chapters.value.push(
            ...newChapters.filter(
              (chapter) => chapter.monthNumber !== currentMonthNumber,
            ),
          )
        }
        chapterPage.value += 1
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  const getMonthNumbers = async function () {
    try {
      const allMonthNumbers = await getCircleMonthNumbers(circleId)
      const currentMonthNumber =
        new Date().getMonth() + new Date().getFullYear() * 12
      monthNumbers.value = allMonthNumbers.filter(
        (monthNumber) => monthNumber !== currentMonthNumber,
      )
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const getPurchasedMonthNumbers = async function () {
    try {
      activeMonthNumbers.value = await getActiveMonthNumbers(circleId)
      selectedMonthNumbers.value = Array.from(
        new Set([...selectedMonthNumbers.value, ...activeMonthNumbers.value]),
      )
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const toggleMonthNumber = function (monthNumber) {
    if (circle.value.forceComplement) {
      return
    }

    if (activeMonthNumbers.value.includes(monthNumber)) {
      return
    }

    if (selectedMonthNumbers.value.includes(monthNumber)) {
      selectedMonthNumbers.value = selectedMonthNumbers.value.filter(
        (interMonthNumber) => interMonthNumber !== monthNumber,
      )
    } else {
      selectedMonthNumbers.value.push(monthNumber)
    }
  }

  const checkPaidStatus = async function () {
    const checkPaid = async function () {
      try {
        const circle = await getCircle(circleId)
        if (circle.isMember) {
          Toast(
            {
              message: 'payment succeed',
            },
            'ok',
          )
        } else {
          setTimeout(checkPaid, 1500)
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      }
    }

    checkPaid()
  }

  const intentPurchase = function () {
    const date = new Date()
    const thisMonthNumber = date.getMonth() + date.getFullYear() * 12
    const monthNumbers = Array.from(
      new Set([...selectedMonthNumbers.value, thisMonthNumber]),
    )
    console.log('monthNumbers', monthNumbers)
    openPaymentPicker({
      circleId,
      processors: ['order2faka', 'alipay', 'wechat'],
      monthNumbers: monthNumbers,
    })
  }

  onMounted(async () => {
    if (!plainCircle) {
      circle.value = await getCircle(circleId)
    } else if (justPaid.value) {
      checkPaidStatus()
    }
    if (circle.value) {
      getMonthNumbers()
      getChapters()
      if (authenticated.value) {
        getPurchasedMonthNumbers()
      }
    }
  })

  return {
    intentPurchase,
    upfrontPriceText,
    subscribePriceText,
    subscribePrice,
    price,
    priceText,
    selectedMonthNumbers,
    complementPrice,
    complementPriceText,
    getChapters,
    chapterHasmore,
    loading,
    monthNumbers,
    toggleMonthNumber,
    chapters,
    complementCount,
  }
}
