<template>
  <div class="user-posts-search">
    <div class="search-bar">
      <input-text
        v-model="q"
        ref="inputRef"
        :placeholder="placeholder"
        @keyup.enter="search"
      ></input-text>

      <Button
        icon="pi pi-times"
        variant="text"
        @click="$emit('close')"
      ></Button>
    </div>

    <post-list :posts="posts"></post-list>

    <load-more-button
      v-show="lastQuery"
      :busy="busy"
      :has-more="hasMore"
      @trigger="search"
    ></load-more-button>
  </div>
</template>

<script>
//@ts-check
import { searchPosts } from '@/api/post'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import PostList from '@/components/PostList.vue'
import { useToast } from '@/composables/toast'
import { until, useFocus } from '@vueuse/core'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { onMounted, ref } from 'vue'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const q = ref('')
    const inputRef = ref(null)
    const posts = ref([])
    const busy = ref(false)
    const hasMore = ref(false)
    const page = ref(1)
    const limit = 10
    const { showToast } = useToast()
    const lastQuery = ref('')

    const { focused } = useFocus(inputRef, { initialValue: true })

    const search = async () => {
      try {
        busy.value = true

        if (lastQuery.value !== q.value) {
          lastQuery.value = q.value
          page.value = 1
          posts.value = []
          hasMore.value = false
        }

        const _posts = await searchPosts(
          q.value,
          limit,
          page.value,
          props.userId,
        )

        if (page.value === 1) {
          posts.value = _posts
        } else {
          posts.value.push(..._posts)
        }

        hasMore.value = _posts.length === limit

        if (hasMore.value) {
          page.value += 1
        }
      } catch (err) {
        showToast(err.message)
      } finally {
        busy.value = false
      }
    }

    onMounted(async () => {
      await until(inputRef).toMatch((v) => v !== null)
      focused.value = true
    })

    return {
      q,
      posts,
      busy,
      hasMore,
      search,
      page,
      lastQuery,
    }
  },
  components: {
    PostList,
    LoadMoreButton,
    InputText,
    Button,
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.user-posts-search {
  padding: 1rem;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    flex: 1;
  }

  button {
    min-width: 2rem;
    min-height: 2rem;
  }
}
</style>
