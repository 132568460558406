//@ts-check
import { update } from '@/api/user'
import { useUserStore } from '@/pinia/user'
import { convertClientPricesToServerPrices, isValidDomain } from '@/utils'
import { whenever } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from './toast'
import { useUserAskEdit } from './user_ask_edit'
import { useCurrentUser } from './user_data'
import { useUserIssueEdit } from './user_issue_edit'

export const useUserProfile = function () {
  const nickname = ref('')
  const gender = ref(0)
  const birthday = ref(0)
  const motto = ref('')
  const username = ref('')
  const { authenticated, currentUser } = useCurrentUser()
  const { t } = useI18n()
  const userStore = useUserStore()
  const { Toast } = useToast()
  const loading = ref(false)
  const { availableForIssue, issueTemplate, minIssuePrices } = useUserIssueEdit()
  const { availableForAsk, askPrices } = useUserAskEdit()

  whenever(
    authenticated,
    () => {
      nickname.value = currentUser.value.nickname
      gender.value = currentUser.value.gender
      birthday.value = currentUser.value.birthday
      motto.value = currentUser.value.motto
      username.value = currentUser.value.username
    },
    { immediate: true },
  )

  const saveProfile = async function () {
    try {
      if (username.value) {
        if (username.value.includes(':')) {
          throw new Error('please do not input http:// or https://')
        }

        if (!isValidDomain(`${username.value}.pero.app`)) {
          throw new Error(`${username.value}.pero.app is not a valid domain`)
        }
      }

      loading.value = true

      const doc = {
        nickname: nickname.value,
        gender: gender.value,
        birthday: birthday.value,
        motto: motto.value,
        username: username.value,
        availableForIssue: availableForIssue.value,
        issueTemplate: issueTemplate.value,
        minIssuePrices: convertClientPricesToServerPrices(minIssuePrices.value.prices),
        availableForAsk: availableForAsk.value,
        askPrices: convertClientPricesToServerPrices(askPrices.value.prices),
      }

      await update(currentUser.value._id, doc)

      for (const key of Object.keys(doc)) {
        userStore.patchSelf(key, doc[key])
      }
      Toast(t('success'), 'ok')
    } catch (err) {
      Toast({
        message: err.message,
      })
    } finally {
      loading.value = false
    }
  }

  return {
    nickname,
    gender,
    birthday,
    motto,
    saveProfile,
    username,
    loading,
    availableForIssue,
    issueTemplate,
    minIssuePrices,
    availableForAsk,
    askPrices,
  }
}
