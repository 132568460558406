<template>
  <div class="checkout-container">
    <div class="shopping-item-container">
      <h1 v-if="itemTitle">{{ itemTitle }}</h1>
      <img v-if="imageSrc" loading="lazy" :src="imageSrc" />
    </div>

    <div class="payment-container">
      <div class="stripe">
        <div class="stripe-form">
          <!-- Elements will create form elements here -->
        </div>

        <pr-button class="checkout-button" type="primary" @click.prevent="stripeConfirm"> Checkout </pr-button>
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import { getCircle } from '@/api/circle'
import postapi from '@/api/post'
import { getOne as getOneUser } from '@/api/user'
import PrButton from '@/components/PRButton.vue'
import { useToast } from '@/composables/toast'
import { APP_URL } from '@/config'
import { getStripe } from '@/libs/stripe'
import { MediaType } from '@perohub/libpero/dist/types'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    let elements = null
    const item = ref(null)
    const route = useRoute()
    const postId = route.query.postId
    const circleId = route.query.circleId
    const userId = route.query.userId
    const { Toast } = useToast()
    const loading = ref(false)

    const itemTitle = computed(() => {
      if (!item.value) {
        return null
      }

      if (postId) {
        return item.value.title
      }

      if (userId) {
        return item.value.nickname
      }

      if (circleId) {
        return item.value.title
      }

      return null
    })

    const imageSrc = computed(() => {
      if (!item.value) {
        return null
      }

      if (postId) {
        const freePictures = item.value.pictures.filter((media) => {
          if (item.value.price > 0) {
            return media.canBrowse && media.type === MediaType.image
          }

          return media.type === MediaType.image
        })

        if (freePictures.length > 0) {
          return freePictures[0].thum
        }

        const firstMedia = item.value.pictures.filter((media) => media.thum)[0]
        if (firstMedia) {
          return firstMedia.thum
        }

        return null
      }

      if (userId) {
        return item.value.avatarURL
      }

      if (circleId) {
        return item.value.coverUrl
      }

      return null
    })

    const setupStripeElements = async function (clientSecret) {
      try {
        loading.value = true
        const stripe = await getStripe()
        elements = stripe.elements({
          clientSecret,
        })
        const paymentElement = elements.create('payment')
        paymentElement.mount('.stripe-form')
        paymentElement.on('loaderror', ({ error }) => {
          Toast({
            message: error.message,
          })
        })
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }

    const stripeConfirm = async function () {
      try {
        if (!elements) {
          throw new Error('Payment form is not set up yet, please check your network')
        }

        loading.value = true
        const stripe = await getStripe()
        let returnUrl = ''

        if (postId) {
          returnUrl = `${APP_URL}/posts/${postId}`
        } else if (userId) {
          returnUrl = `${APP_URL}/users/${userId}?showContact=1`
        } else if (circleId) {
          returnUrl = `${APP_URL}/circles/${circleId}`
        }

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })

        if (error) {
          throw error
        }
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      try {
        loading.value = true

        if (postId) {
          item.value = await postapi.getOne(postId)
        } else if (userId) {
          item.value = await getOneUser(userId)
        } else if (circleId) {
          item.value = await getCircle(circleId)
        }

        const clientSecret = route.query.clientSecret
        if (clientSecret) {
          await setupStripeElements(clientSecret)
        }
      } catch (err) {
        console.error(err)
        Toast({
          message: err.message,
        })
      } finally {
        loading.value = false
      }
    })

    return {
      item,
      stripeConfirm,
      setupStripeElements,
      imageSrc,
      itemTitle,
    }
  },
  components: {
    PrButton,
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.checkout-container {
  display: flex;
  justify-content: center;
  width: 840px;
  margin-left: calc((100% - 840px) / 2);

  > div {
    padding: 1em;
    flex-grow: 1;
  }

  @media @phone {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
}

.shopping-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  h1 {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
  }

  img {
    border-radius: 6px;
    object-fit: cover;
    width: 10em;
    height: 14.14em;
  }
}

.payment-container {
  width: 100%;

  @media @desktop {
    min-height: 22em;
  }
}

.checkout-button {
  margin-top: 1em;

  @media @phone {
    width: 100%;
  }
}
</style>
