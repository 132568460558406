<template>
  <div
    v-if="user"
    class="container"
  >
    <out-of-service-cover
      v-if="user.isR18 && countryCode === 'CN'"
    ></out-of-service-cover>
    <div v-if="user.status === 0 || !user._id">
      <user-info
        class="user-info"
        :user="user"
      ></user-info>

      <user-posts-search
        :user-id="user._id"
        v-show="showSearch"
        :placeholder="$t('searchUserArtworks', { nickname: user.nickname })"
        @close="showSearch = false"
      ></user-posts-search>

      <pr-tabs
        v-if="user.authed || allowPirate"
        v-show="!showSearch"
        v-model="selected"
        :tab-count="userPageTabCount"
        as="segmented-control"
        style="margin-top: 1rem"
      >
        <template #header-0>
          {{ $t('latest') }}
        </template>
        <template #header-1>
          {{ $t('hot') }}
        </template>
        <template
          v-if="showCirclesTab"
          #header-2
        >
          {{ $t('circle') }}
        </template>
        <template
          v-if="showIssuesTab"
          #[`header-${issuesTabIndex}`]
        >
          {{ $t('assignment') }}
        </template>
        <template
          v-if="showAsksTab"
          #[`header-${asksTabIndex}`]
        >
          {{ $t('questions') }}
        </template>

        <template #tab-0>
          <div
            v-if="tags.length > 0"
            class="self-tags"
          >
            <self-tag-selector
              :tag="{ title: 'Artworks' }"
              :selected="selectedTagId === ''"
              class="touchable"
              :class="{ hoverable: !isOnMobile }"
              @click="selectedTagId = ''"
            >
            </self-tag-selector>

            <self-tag-selector
              v-for="tag in tags"
              :key="tag._id"
              :tag="tag"
              :selected="selectedTagId === tag._id"
              class="touchable"
              :class="{ hoverable: !isOnMobile }"
              @click="selectedTagId = tag._id"
            >
            </self-tag-selector>
          </div>

          <div v-show="selectedTagId">
            <post-list
              :class="{ 'mobile-wrap': isOnMobile }"
              :posts="tagArtworks"
            >
            </post-list>
          </div>

          <div v-show="!selectedTagId">
            <post-list
              :class="{ 'mobile-wrap': isOnMobile }"
              :posts="latestPosts"
            >
            </post-list>
            <load-more-button
              :busy="latestLoading"
              :has-more="latestHasmore"
              :trigger="getLatestPosts"
            >
            </load-more-button>
          </div>
        </template>

        <template #tab-1>
          <post-list
            :class="{ 'mobile-wrap': isOnMobile }"
            :posts="hotPosts"
          ></post-list>
          <load-more-button
            :busy="hotLoading"
            :has-more="hotHasmore"
            :trigger="getHotPosts"
          ></load-more-button>
        </template>

        <template
          v-if="showCirclesTab"
          #tab-2
        >
          <div
            :class="{
              'circles-container': !isOnMobile,
            }"
          >
            <router-link
              v-for="circle in circles"
              :key="circle._id"
              :to="`/circles/${circle._id}`"
              :class="{
                'mobile-wrap': isOnMobile,
              }"
            >
              <circle-cell-desktop
                v-if="!isOnMobile"
                :circle="circle"
              >
              </circle-cell-desktop>
              <circle-cell
                v-if="isOnMobile"
                :circle="circle"
              >
              </circle-cell>
            </router-link>
          </div>
        </template>

        <template
          v-if="showIssuesTab"
          #[`tab-${issuesTabIndex}`]
        >
          <div style="padding-top: 1rem">
            <pr-button
              v-if="currentUserId !== userId"
              style="width: 100%"
              type="primary"
              :disabled="!user.issue.available"
              @click="
                $router.push(
                  `/users/${$route.params.userId}/commissions/create`,
                )
              "
            >
              {{
                user.issue.available
                  ? $t('createAssignment')
                  : $t('assignmentUnavailable')
              }}
            </pr-button>
            <issue-list
              :issues="issues"
              style="margin-top: 1rem"
            >
            </issue-list>
            <load-more-button
              :busy="issueLoading"
              :has-more="issueHasMore"
              :trigger="fetchIssues"
            >
            </load-more-button>
          </div>
        </template>

        <template
          v-if="showAsksTab"
          #[`tab-${asksTabIndex}`]
        >
          <div style="padding-top: 1rem">
            <pr-button
              v-if="currentUserId !== userId"
              style="width: 100%"
              type="primary"
              @click="
                $router.push(`/users/${$route.params.userId}/asks/create`)
              "
            >
              {{ $t('askAnonymousQuestion') }}
            </pr-button>
            <ask-list :asks="asks"></ask-list>
            <load-more-button
              :busy="askLoading"
              :has-more="askHasMore"
              :trigger="getAsks"
            >
            </load-more-button>
          </div>
        </template>
      </pr-tabs>
      <div
        v-else
        class="unauthorized"
      >
        This user is not a verified artist
      </div>
    </div>
    <banned
      v-else-if="user.status !== 0"
      :user="user"
    ></banned>
  </div>
</template>

<script>
//@ts-check
import AskList from '@/components/AskList.vue'
import Banned from '@/components/Banned.vue'
import CircleCell from '@/components/CircleCell.vue'
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import IssueList from '@/components/IssueList.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import OutOfServiceCover from '@/components/OutOfServiceCover.vue'
import PostList from '@/components/PostList.vue'
import PrButton from '@/components/PRButton.vue'
import PrTabs from '@/components/PrTabs.vue'
import SelfTagSelector from '@/components/SelfTagSelector.vue'
import UserInfo from '@/components/UserInfo.vue'
import UserPostsSearch from '@/components/UserPostsSearch.vue'
import { useAssigneeAsks } from '@/composables/ask_assignee'
import { useCurrentCountry } from '@/composables/country'
import { useAssigneeIssues } from '@/composables/issue_assignee'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useUserPageLayout } from '@/composables/user_page_layout'
import { useUserPresent } from '@/composables/user_present'
import { useUserSelfTagList } from '@/composables/user_selftag'
import { allowPirate } from '@/config'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    const userPresent = useUserPresent()
    const assigneeIssues = useAssigneeIssues(userPresent.userId)
    const assigneeAsks = useAssigneeAsks(userPresent.userId)
    const userPageLayout = useUserPageLayout({
      userRef: userPresent.user,
      issuesRef: assigneeIssues.issues,
      asksRef: assigneeAsks.asks,
      userCirclesRef: userPresent.circles,
    })
    const route = useRoute()
    const router = useRouter()

    const showSearch = computed({
      get() {
        return route.query.search === 'true'
      },
      set(value) {
        router.replace(`/users/${route.params.userId}?search=${value}`)
      },
    })

    return {
      ...assigneeAsks,
      ...assigneeIssues,
      ...userPageLayout,
      ...useResponsive(),
      ...userPresent,
      ...useCurrentCountry(),
      ...useUserSelfTagList(),
      ...useCurrentUser(),
      allowPirate,
      showSearch,
    }
  },
  data() {
    return {
      selected: 0,
    }
  },
  components: {
    UserInfo,
    LoadMoreButton,
    Banned,
    PostList,
    OutOfServiceCover,
    SelfTagSelector,
    CircleCell,
    CircleCellDesktop,
    IssueList,
    PrButton,
    PrTabs,
    AskList,
    UserPostsSearch,
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.unauthorized {
  padding-top: 2em;
  text-align: center;
}

.user-info {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.segmented-control {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 800px) {
  .container {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .user-info {
    padding-left: 0px;
    padding-right: 0px;
  }
  .segmented-control {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.mobile-wrap {
  margin-right: 10px;
  margin-left: 10px;
}

.self-tags {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.circles-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
}
</style>
