//@ts-check
import {
  completeMultipartUpload,
  getMultipartUploadUrl,
  getPrivateUploadUrl,
  startMultipartUpload,
  uploadPart,
  uploadPrivate,
} from '@/api/s3'
import { MediaType } from '@perohub/libpero/dist/types'
import localforage from 'localforage'
import pLimit from 'p-limit'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from './toast'

const tenMb = 1024 * 1024 * 10
export const sliceThrehold = 1024 * 1024 * 128

const isImageFile = function (file) {
  return /^image\//.test(file.type)
}

const isVideoFile = function (file) {
  return /^video\//.test(file.type)
}

const isObjectFile = function (file) {
  return /^model\/obj/.test(file.type)
}

const isArchiveFile = function (file) {
  return (
    /^application\/(x-rar-compressed|x-rar|vnd.rar|octet-stream|zip)/.test(file.type) ||
    file.name.endsWith('.rar') ||
    file.name.endsWith('.zip')
  )
}

export const getFileType = function (file) {
  console.log(file.type)
  if (isImageFile(file)) {
    return MediaType.image
  }

  if (isVideoFile(file)) {
    return MediaType.video
  }

  if (isObjectFile(file)) {
    return MediaType.obj
  }

  if (isArchiveFile(file)) {
    return MediaType.file
  }

  return MediaType.file
}

export const getDataURL = async function (file) {
  if (isVideoFile(file)) {
    return [URL.createObjectURL(file), 0, 0]
  }

  if (isImageFile(file)) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        const imgDom = new Image()
        imgDom.src = event.target.result.toString()

        imgDom.onload = function () {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          const originWidth = imgDom.width
          const originHeight = imgDom.height
          const maxWidth = 400
          const maxHeight = 400
          let targetWidth = originHeight
          let targetHeight = originHeight

          if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
              // 更宽，按照宽度限定尺寸
              targetWidth = maxWidth
              targetHeight = Math.round(maxWidth * (originHeight / originWidth))
            } else {
              targetHeight = maxHeight
              targetWidth = Math.round(maxHeight * (originWidth / originHeight))
            }
          }
          // canvas对图片进行缩放
          canvas.width = targetWidth
          canvas.height = targetHeight
          // 清除画布
          context.clearRect(0, 0, targetWidth, targetHeight)
          // 图片压缩
          context.drawImage(imgDom, 0, 0, targetWidth, targetHeight)
          const dataURL = canvas.toDataURL()

          resolve([dataURL, originHeight, originWidth])
        }
      }

      reader.onerror = function (err) {
        reject(err)
      }

      reader.readAsDataURL(file)
    })
  }

  return [null, 0, 0]
}

const isUploadedBefore = async function (s3KeyPath) {
  const uploadedPaths = await localforage.getItem('perohub_uploaded_paths')
  if (!uploadedPaths || !uploadedPaths.includes) {
    await localforage.setItem('perohub_uploaded_paths', [])

    return false
  }

  return uploadedPaths.includes(s3KeyPath)
}

const cacheKeyPath = async function (s3KeyPath) {
  const uploadedPaths = await localforage.getItem('perohub_uploaded_paths')
  if (!uploadedPaths || !uploadedPaths.includes) {
    await localforage.setItem('perohub_uploaded_paths', [s3KeyPath])
  }

  uploadedPaths.push(s3KeyPath)
  await localforage.setItem('perohub_uploaded_paths', uploadedPaths)
}

const getConcurrencyLimit = (fileSize) => {
  if (fileSize > 1024 * 1024 * 1024) {
    // > 1GB
    return 20
  } else if (fileSize > 1024 * 1024 * 100) {
    // > 100MB
    return 15
  }
  return 10
}

export const useUploadMedia = function () {
  const mediaInputRef = ref(null)
  const previews = ref([])
  const uploading = ref(false)
  const reading = ref(false)
  const total = ref(0)
  const uploads = ref([])
  const { t } = useI18n()
  const { Toast } = useToast()

  const progress = computed(() => {
    if (total.value === 0) {
      return 0
    }

    let totalUploaded = 0
    // @ts-ignore
    for (let uploaded of uploads.value.values()) {
      if (Array.isArray(uploaded)) {
        uploaded = uploaded.reduce((accu, cur) => {
          return accu + cur
        }, 0)
      } else if (isNaN(uploaded)) {
        uploaded = 0
      }
      totalUploaded += uploaded
    }

    return (totalUploaded * 100) / total.value
  })

  const indicatorText = computed(() => {
    if (reading.value) {
      return t('loading')
    }

    return t('clickToSelect')
  })

  watch(progress, () => {
    console.log(progress.value)
  })

  const uploadOne = async function (preview, index) {
    try {
      const file = preview.file

      let fileType = file.type
      if (!fileType || fileType === '') {
        if (file.name.endsWith('.rar')) {
          fileType = 'application/x-rar-compressed'
        } else if (file.name.endsWith('.zip')) {
          fileType = 'application/zip'
        } else {
          fileType = 'application/octet-stream'
        }
      }

      const signedUrl = await getPrivateUploadUrl(file.name, fileType, file.size, file.lastModified)
      const path = new URL(signedUrl).pathname

      if (await isUploadedBefore(path)) {
        return {
          path,
          type: preview.type,
          canBrowse: preview.canBrowse,
          filename: preview.filename,
          mime: fileType,
        }
      }

      await uploadPrivate(signedUrl, file, (loaded) => {
        uploads.value[index] = loaded
      })

      await cacheKeyPath(path)

      uploads.value[index] = file.size

      return {
        path,
        type: preview.type,
        canBrowse: preview.canBrowse,
        filename: preview.filename,
        mime: fileType,
      }
    } catch (err) {
      Toast({
        message: err.message,
      })
      throw err
    }
  }

  const uploadSlicedOne = async function (preview, index) {
    try {
      const file = preview.file

      let fileType = file.type
      if (!fileType || fileType === '') {
        if (file.name.endsWith('.rar')) {
          fileType = 'application/x-rar-compressed'
        } else if (file.name.endsWith('.zip')) {
          fileType = 'application/zip'
        } else {
          fileType = 'application/octet-stream'
        }
      }

      const { key, uploadId } = await startMultipartUpload(file.name, fileType, file.size, file.lastModified)

      if (await isUploadedBefore(key)) {
        return {
          path: key,
          type: preview.type,
          canBrowse: preview.canBrowse,
          filename: preview.filename,
          mime: fileType,
        }
      }

      const upload = async function (chunk, uploadId, key, partNumber, actualChunkSize) {
        try {
          const url = await getMultipartUploadUrl(key, partNumber, uploadId, preview.mime)
          const part = await uploadPart(url, chunk, partNumber, (loaded) => {
            const uploadedArray = uploads.value[index] ?? []
            const innerIndex = partNumber - 1
            uploadedArray[innerIndex] = Math.min(loaded, actualChunkSize)
            uploads.value[index] = uploadedArray
          })
          return part
        } catch (err) {
          Toast({
            message: `Failed to upload part ${partNumber}: ${err.message}`,
          })
          throw err
        }
      }

      const chunkCount = Math.ceil(file.size / tenMb)
      const chunkSize = tenMb
      const limit = pLimit(getConcurrencyLimit(file.size))
      const parts = []

      for (let i = 0; i < chunkCount; i++) {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, file.size)
        const partNumber = i + 1

        try {
          const part = await limit(async () => {
            const chunk = file.slice(start, end)
            return upload(chunk, uploadId, key, partNumber, end - start)
          })
          parts.push(part)
        } catch (err) {
          Toast({
            message: `Failed to upload chunk ${partNumber}: ${err.message}`,
          })
          throw err // Re-throw to trigger the outer catch block
        }
      }

      await completeMultipartUpload(parts, uploadId, key, preview.mime)
      await cacheKeyPath(key)

      uploads.value[index] = 100

      return {
        path: key,
        type: preview.type,
        canBrowse: preview.canBrowse,
        filename: preview.filename,
        mime: fileType,
      }
    } catch (err) {
      Toast({
        message: `Upload failed: ${err.message}`,
      })
      throw err
    }
  }

  const triggerFileInput = async function (event) {
    const files = event.target.files

    if (files.length <= 0) {
      return
    }

    reading.value = true
    const makePreview = async function (file) {
      const [dataURL, originHeight, originWidth] = await getDataURL(file)

      let fileType = file.type
      if (!fileType || fileType === '') {
        if (file.name.endsWith('.rar')) {
          fileType = 'application/x-rar-compressed'
        } else if (file.name.endsWith('.zip')) {
          fileType = 'application/zip'
        } else {
          fileType = 'application/octet-stream'
        }
      }

      return {
        data: dataURL,
        originHeight,
        originWidth,
        file,
        progress: 0,
        failed: false,
        canBrowse: false,
        type: getFileType(file),
        mime: fileType,
        filename: file.name,
      }
    }

    const promises = []
    for (const file of Array.from(files).values()) {
      promises.push(makePreview(file))
    }
    const newPreviews = await Promise.all(promises)

    previews.value.push(...newPreviews)

    reading.value = false
    event.target.value = ''
  }

  const uploadMedias = async function () {
    try {
      uploading.value = true
      total.value = previews.value.reduce((accumulator, current) => {
        return accumulator + current.file.size
      }, 0)
      uploads.value = []

      const promises = []
      // @ts-ignore
      for (const [index, preview] of previews.value.entries()) {
        if (preview.file.size > sliceThrehold) {
          uploads.value[index] = []
          promises.push(uploadSlicedOne(preview, index))
        } else {
          uploads.value[index] = 0
          promises.push(uploadOne(preview, index))
        }
      }

      const medias = await Promise.all(promises)
      return medias
    } finally {
      uploading.value = false
      total.value = 0
      uploads.value = []
    }
  }

  return {
    mediaInputRef,
    triggerFileInput,
    previews,
    uploadMedias,
    reading,
    indicatorText,
    progress,
    uploading,
    total,
    uploadOne,
    uploadSlicedOne,
    uploads,
  }
}
