<template>
  <div class="issue-cell-container">
    <div class="content-wrapper">
      <user-avatar
        :user="issue.assigner"
        :disabled="true"
        class="avatar"
        @click="$router.push(`/users/${issue.assigner._id}`)"
      >
      </user-avatar>

      <div class="content">
        <p>{{ issue.content }}</p>
        <absolute-cover v-if="!involved">
          <p>You are not involved in this commission</p>
        </absolute-cover>
      </div>
    </div>

    <div class="bottom-right">
      <div class="price">
        {{ priceText }}
      </div>
      <div class="status">
        {{ $t(issue.status) }}
      </div>
    </div>
  </div>
</template>

<script>
//@ts-check
import { useCurrentUser } from '@/composables/user_data'
import { convertServerPriceToClientPrice } from '@/utils'
import { format } from 'timeago.js'
import { computed } from 'vue'
import AbsoluteCover from './AbsoluteCover.vue'
import UserAvatar from './UserAvatar.vue'

export default {
  props: {
    issue: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { currentUserId } = useCurrentUser()
    const timeago = computed(() =>
      format(props.issue.createdAt, navigator.language),
    )
    const involved = computed(
      () =>
        props.issue.assignee._id === currentUserId.value ||
        props.issue.assigner._id === currentUserId.value,
    )

    const priceText = computed(() => {
      return `${props.issue.currency} ${convertServerPriceToClientPrice(props.issue.price, props.issue.currency)}`
    })

    return {
      involved,
      timeago,
      priceText,
    }
  },
  components: {
    UserAvatar,
    AbsoluteCover,
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.issue-cell-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  border: 1px solid @border;
  border-radius: 0.5rem;
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.avatar {
  width: 4rem;
  height: 4rem;
}

.content {
  flex: 1;
  position: relative;
}

.bottom-right {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price {
  font-size: 1.4rem;
  font-weight: bold;
  font-style: italic;
  color: @yellow;
}

.status {
  font-size: 0.8rem;
}
</style>
