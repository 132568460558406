<template>
  <div class="user-avatar-container">
    <transition-image
      :styles="{
        width: ' 100%',
        height: '100%',
        borderRadius: '50%',
        objectFit: 'cover',
      }"
      :class-names="['hoverable']"
      :src="user.avatarURL"
      :alt="user.nickname"
      @mouseover="infoVisible = true"
      @mouseleave="infoVisible = false"
    >
    </transition-image>

    <img
      v-if="user.officialBadge"
      loading="lazy"
      class="badge"
      :src="user.officialBadge.iconUrl"
    />

    <user-info
      v-if="!isOnMobile && !disabled && user.status === 0"
      v-show="infoVisible"
      class="info"
      :user="user"
      @mouseover="infoVisible = true"
      @mouseleave="infoVisible = false"
    ></user-info>
  </div>
</template>

<script>
//@ts-check
import TransitionImage from '@/components/TransitionImage.vue'
import UserInfo from '@/components/UserInfo.vue'
import { useResponsive } from '@/composables/responsive'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ...useResponsive(),
    }
  },
  data() {
    return {
      infoVisible: false,
    }
  },
  components: {
    UserInfo,
    TransitionImage,
  },
}
</script>

<style lang="less" scoped>
.user-avatar-container {
  position: relative;
  display: inline-block;

  .badge {
    display: block;
    position: absolute;
    bottom: 0.1rem;
    right: -0.3rem;
    width: 1rem;
    height: 1rem;
  }

  .info {
    z-index: 6;
    position: absolute;
    top: 90%;
    left: -150px;
  }
}
</style>
