<template>
  <div v-if="isOnMobile" class="container">
    <div class="top">
      <img class="back" src="@/assets/Navigation_Back@2x.png" @click="$router.go(-1)" />
      <span v-if="post">{{ post.title }}的评论</span>
    </div>
    <comment></comment>
  </div>

  <div v-else class="comiccontainer">
    <router-link :to="`/comics/${$route.params.postId}`">
      <post-list-item class="touchable hoverable postcomic" :post-id="$route.params.postId"></post-list-item>
    </router-link>
    <comment class="comment"></comment>
  </div>
</template>

<script>
import Comment from '@/components/Comment.vue'
import PostListItem from '@/components/PostListItem.vue'
import { useArtworkPresent } from '@/composables/artwork_present'
import { useCurrentCurrency } from '@/composables/currency'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const { currency } = useCurrentCurrency()
    const route = useRoute()

    return {
      ...useResponsive(),
      ...useCurrentUser(),
      ...useArtworkPresent(route.params.postId, currency),
    }
  },
  components: {
    PostListItem,
    Comment,
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

@media (min-width: 800px) {
  .container {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 800px) {
  .container {
    width: 100%;
    height: auto;
  }
}

.top {
  width: 100%;
  line-height: 44px;
  height: 44px;
  background-color: #ffffff;
  text-align: center;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 8px;
    top: 12px;
  }

  span {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    color: #333333;
  }
}

@media (min-width: 800px) {
  .comiccontainer {
    width: 780px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .comment {
    width: 500px;
    float: right;
  }

  .postcomic {
    float: left;
    margin-top: 20px;
  }
}
</style>
