<template>
  <div class="container">
    <p class="title">
      {{ $t('allRecommendedUsers') }}
    </p>

    <div class="users">
      <user-list-item v-for="user in users" :key="user._id" :user="user" class="user touchable hoverable"
        @click="$router.push(`/users/${user._id}`)">
      </user-list-item>
    </div>
  </div>
</template>

<script>
import { getGirls } from '@/api/explore'
import UserListItem from '@/components/UserListItem.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { useHead } from '@unhead/vue'


export default {
  components: {
    UserListItem
  },
  setup() {
    useHead({
      title: 'Explore | Perohub',
    })

    return {
      ...useResponsive(),
      ...useToast(),
    }
  },
  data() {
    return {
      users: [],
      topics: [],
      page: 1,
      busy: false,
      hasMore: true,
      swiperOption: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperSlides: []
    }
  },
  mounted() {
    this.getUsers()

  },
  methods: {
    getUsers() {
      getGirls()
        .then(users => {
          this.users = users
        })
        .catch(err => {
          console.error(err)

          this.showToast({
            message: err.message ? err.message : err
          })
        })
    },
    enterTopic(topicId) {
      const topic = this.topics.filter(topic => topic._id === topicId)[0]

      if (topic.type === 'web') {
        location.href = topic.url
      } else if (topic.type === 'posts') {
        this.$router.push(`/topics/${topicId}`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/base.less";

.users {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1rem;

  @media @phone {
    grid-template-columns: 1fr;
  }

  .user {
    height: 4rem;
  }
}

.container {
  margin-top: 28px;
  width: 100%;
  padding-bottom: 50px;
  padding: 0.5rem;
}



@media (min-width: 800px) {
  .container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;

  }

  .title {
    height: 21px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    margin-bottom: 10px;
    padding-left: 4px;
  }
}
</style>
