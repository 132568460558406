//@ts-check
import { getPostTranslation } from '@/api/post'
import { useArtworkStore } from '@/pinia/artwork'
import { locale } from '@/translations'
import { computed, h, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from './toast'

export const useArtworkText = function (artworkId) {
  const artworkStore = useArtworkStore()
  const currentLocale = ref(locale)
  const { Toast } = useToast()
  const route = useRoute()

  if (!artworkId) {
    artworkId = route.params.postId
  }

  const artworkLocales = computed(() => {
    if (!artwork.value) {
      return []
    }

    if (!artwork.value.contentLocales) {
      return []
    }

    return Object.keys(artwork.value.contentLocales)
      .filter((locale) => artwork.value.contentLocales[locale])
      .sort()
  })

  const artwork = computed(() => {
    return artworkStore.artworks[artworkId]
  })

  const artworkTextContent = computed(() => {
    if (!artwork.value) {
      return ''
    }

    return linkify(artwork.value.content)
  })

  const localizedContents = computed(() => {
    if (!artwork.value) {
      return {}
    }

    const contents = {}

    if (!artwork.value.contentLocales) {
      return contents
    }

    for (const locale of Object.keys(artwork.value.contentLocales)) {
      contents[locale] = linkify(artwork.value.contentLocales[locale])
    }

    return contents
  })

  watch(artwork, async () => {
    if (artwork.value && !artwork.value.contentLocales) {
      await attachTranslations(artwork.value._id)
    }
  })

  const attachTranslations = async function (artworkId) {
    try {
      const translations = await getPostTranslation(artworkId)
      artworkStore.attachTranslations(artworkId, translations)
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const linkify = function (content) {
    if (!artwork) {
      return ''
    }

    content = content.replace(/\r\n/g, '<br/>')

    if (artwork.value.links.length === 0) {
      return content
    }

    const links = artwork.value.links

    if (links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        //contentlink = content.replace('a', 'o');
        if (links[i].type == 'web') {
          const routerLink = h(
            'router-link',
            {
              to: links[i].options.url,
            },
            links[i].textMatch,
          )
          content = content.replace(links[i].textMatch, routerLink)
        } else if (links[i].type == 'user') {
          const routerLink = h(
            'router-link',
            {
              to: `/users/${links[i].options.userId}`,
            },
            links[i].textMatch,
          )
          content = content.replace(links[i].textMatch, routerLink)
        } else if (links[i].type == 'channel') {
          const routerLink = h(
            'router-link',
            {
              to: `/channels/${links[i].options.channelId}`,
            },
            links[i].textMatch,
          )
          content = content.replace(links[i].textMatch, routerLink)
        }
      }
    }

    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
    const arr = content.match(reg)
    if (arr != null) {
      for (let i = 0; i < arr.length; i++) {
        const routerLink = h(
          'router-link',
          {
            to: arr[i],
          },
          arr[i],
        )
        content = content.replace(arr[i], routerLink)
      }
    }

    return content
  }

  onMounted(() => {
    if (route.path.includes('/posts/')) {
      attachTranslations(route.params.postId)
    }
  })

  return {
    artworkLocales,
    localizedContents,
    artworkTextContent,
    currentLocale,
  }
}
