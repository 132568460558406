//@ts-check
import { cdn } from '@/config'
import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * Normalize a URL by removing query parameters and hash fragments
 * to ensure consistent caching regardless of how the URL is accessed
 * @param {string} url - The URL to normalize
 * @returns {string} - The normalized URL
 */
const getCacheKey = (url) => {
  if (!url) {
    return url
  }

  // Don't modify data URLs or blob URLs
  if (url.startsWith('data:') || url.startsWith('blob:')) {
    return url
  }

  try {
    // Handle relative URLs
    if (url.startsWith('/') || !url.match(/^[a-z]+:\/\//i)) {
      // For relative URLs, just return as is
      return url
    }

    // For absolute URLs, remove query parameters and hash fragments
    const urlObj = new URL(url)

    let isFromCDN = false
    for (const cdnHost of Object.values(cdn)) {
      if (urlObj.origin.endsWith(cdnHost)) {
        isFromCDN = true
        break
      }
    }

    if (!isFromCDN) {
      // remove query parameters and hash fragments
      urlObj.search = ''
      urlObj.hash = ''
      return urlObj.toString()
    }

    if (urlObj.searchParams.has('token')) {
      urlObj.searchParams.delete('token')
    }

    return urlObj.toString()
  } catch (e) {
    // If URL parsing fails, return the original URL
    console.warn('Failed to normalize URL:', url, e)
    return url
  }
}

export const useImageCacherStore = defineStore('imageCache', () => {
  // Store blob URLs by original URL
  const blobCache = ref({})

  // Store the fetch promises to avoid duplicate requests
  const fetchPromises = ref({})

  /**
   * Get a cached blob URL if it exists
   * @param {string} url - The original image URL
   * @returns {string|null} - The cached blob URL or null
   */
  const getCachedUrl = (url) => {
    const normalizedUrl = getCacheKey(url)
    return blobCache.value[normalizedUrl] || null
  }

  /**
   * Cache a blob URL for a given original URL
   * @param {string} originalUrl - The original image URL
   * @param {string} blobUrl - The blob URL to cache
   */
  const cacheUrl = (originalUrl, blobUrl) => {
    const normalizedUrl = getCacheKey(originalUrl)
    blobCache.value[normalizedUrl] = blobUrl
  }

  /**
   * Load an image with progress tracking and cache it
   * @param {string} url - The image URL to load
   * @param {Function} onProgress - Progress callback (0-100)
   * @returns {Promise<string>} - A promise that resolves to the blob URL
   */
  const loadImage = async (url, onProgress) => {
    const normalizedUrl = getCacheKey(url)

    // Return cached version if available
    if (blobCache.value[normalizedUrl]) {
      if (onProgress) {
        onProgress(100)
      }
      return blobCache.value[normalizedUrl]
    }

    // Return existing fetch promise if already in progress
    if (fetchPromises.value[normalizedUrl]) {
      return fetchPromises.value[normalizedUrl]
    }

    // Skip progress tracking for data/blob URLs
    if (url.startsWith('data:') || url.startsWith('blob:')) {
      if (onProgress) {
        onProgress(null)
      }
      return url
    }

    // Create new fetch promise
    const fetchPromise = (async () => {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const contentLength = response.headers.get('content-length')
        const total = contentLength ? parseInt(contentLength, 10) : 0

        // If we don't have content length, use indeterminate progress
        if (!contentLength) {
          if (onProgress) {
            onProgress(null)
          }
          const blob = await response.blob()
          const objectURL = URL.createObjectURL(blob)
          cacheUrl(url, objectURL)
          return objectURL
        }

        let loaded = 0
        if (onProgress) {
          onProgress(0)
        }

        // Create a readable stream to track progress
        const stream = new ReadableStream({
          start(controller) {
            const reader = response.body.getReader()

            const read = async () => {
              const { done, value } = await reader.read()
              if (done) {
                controller.close()
                return
              }

              loaded += value.byteLength
              if (onProgress) {
                onProgress(Math.round((loaded / total) * 100))
              }
              controller.enqueue(value)
              return read()
            }

            return read()
          },
        })

        const blob = await new Response(stream).blob()
        const objectURL = URL.createObjectURL(blob)

        // Cache the result
        cacheUrl(url, objectURL)

        return objectURL
      } catch (error) {
        console.error('Error loading image with progress:', error)
        throw error
      } finally {
        // Clear the fetch promise reference
        delete fetchPromises.value[normalizedUrl]
      }
    })()

    // Store the promise
    fetchPromises.value[normalizedUrl] = fetchPromise

    return fetchPromise
  }

  /**
   * Clear all cached blob URLs and revoke them
   */
  const clearCache = () => {
    // Revoke all object URLs to prevent memory leaks
    Object.values(blobCache.value).forEach((blobUrl) => {
      if (typeof blobUrl === 'string' && blobUrl.startsWith('blob:')) {
        URL.revokeObjectURL(blobUrl)
      }
    })

    blobCache.value = {}
  }

  return {
    getCachedUrl,
    cacheUrl,
    loadImage,
    clearCache,
  }
})
