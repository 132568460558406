<template>
  <!-- desktop -->
  <div
    v-if="!isOnMobile"
    class="circles-container"
  >
    <ul
      v-show="showNav"
      class="left"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: $route.path === tab.path }"
        @click="select(index)"
      >
        {{ tab.title }}
      </li>
    </ul>

    <div class="right">
      <router-view
        v-slot="{ Component }"
        class="view"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>

  <!-- mobile -->
  <div
    v-else
    class="circles-mobile-container"
  >
    <pr-tabs
      v-model="selected"
      :tab-count="2"
      as="segmented-control"
    >
      <template #header-0>
        {{ $t('joinedCircles') }}
      </template>
      <template #header-1>
        {{ $t('circles') }}
      </template>

      <template #tab-0>
        <div
          v-if="authenticated"
          class="circles"
        >
          <router-link
            v-for="circle in myCircles"
            :key="circle._id"
            :to="`/circles/${circle._id}`"
          >
            <circle-cell
              class="touchable hoverable circle"
              :circle="circle"
            ></circle-cell>
          </router-link>

          <load-more-button
            :busy="myCirclesLoading"
            :has-more="myCirclesHasmore"
            :trigger="fetchMyCircles"
          ></load-more-button>
        </div>

        <login-cover v-else></login-cover>
      </template>
      <template #tab-1>
        <div class="circles">
          <!-- <div class="kind">
            <h2>{{ kind.title }}</h2>
            <span
              class="touchable hoverable"
              @click="$router.push(`/circle/topic?type=${kind.category}`)">
              {{ $t('viewAll') }}
              <img src="@/assets/icon_page_nextpage_grey.png">
            </span>
          </div> -->

          <circle-cell
            v-for="circle in allCircles"
            :key="circle._id"
            class="touchable hoverable"
            :circle="circle"
            @click="$router.push(`/circles/${circle._id}`)"
          ></circle-cell>

          <load-more-button
            :busy="circleAllLoading"
            :has-more="circleAllHasmore"
            :trigger="fetchAllCircles"
          ></load-more-button>
        </div>
      </template>
    </pr-tabs>
  </div>
</template>

<script>
//@ts-check
import CircleCell from '@/components/CircleCell.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import LoginCover from '@/components/LoginCover.vue'
import PrTabs from '@/components/PrTabs.vue'
import { useAllCircles } from '@/composables/circle_all'
import { useMyCircles } from '@/composables/circle_my'
import { useResponsive } from '@/composables/responsive'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'
import { ref } from 'vue'

export default {
  setup() {
    useHead({
      title: 'Perohub | Artworks Marketplace',
    })

    return {
      selected: ref(1),
      ...useResponsive(),
      ...useAllCircles(),
      ...useCurrentUser(),
      ...useMyCircles(),
    }
  },
  computed: {
    showNav() {
      return this.tabs.map((tab) => tab.path).includes(this.$route.path)
    },
    tabs() {
      const tabs = [
        {
          title: this.$t('joinedCircles'),
          path: '/circles/my',
        },
        {
          title: this.$t('circles'),
          path: '/circles/all',
        },
      ]

      return tabs
    },
  },
  methods: {
    select(index) {
      this.$router.push(this.tabs[index].path)
    },
  },
  components: {
    LoadMoreButton,
    CircleCell,
    LoginCover,
    PrTabs,
  },
}
</script>

<style>
.circles-mobile-container .pr-tabs-header {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 70%;
  margin-left: 15%;
  margin-top: 10px;
}
</style>

<style lang="less" scoped>
@import '@/assets/base.less';

.circles-container {
  margin-top: 14px;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  .left {
    width: 120px;
    margin-right: 40px;
    vertical-align: top;
    margin-left: 40px;

    li {
      display: block;
      height: 30px;
      width: 120px;
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: center;

      &.active {
        color: #9b9b9b;
        border-radius: 15px;
        background-color: #f1f1f1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right {
    flex: 1;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

.view {
  min-height: 400px;
}

@media @phone {
  .kind {
    position: relative;
    margin-bottom: 10px;
    h2 {
      font-size: 23px;
      font-weight: normal;
      color: #4a4a4a;
    }

    span {
      position: absolute;
      right: 4px;
      font-size: 14px;
      color: #cfcfcf;
      top: 3px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
    }
  }
  .circles-mobile-container {
    padding-bottom: 60px;
  }

  .circles {
    padding-left: 10px;
    padding-right: 10px;
    // margin-top: 16px;
    // margin-bottom: 30px;

    .circle {
      margin-bottom: 20px;
    }
  }
}
</style>
