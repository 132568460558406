<template>
  <div class="container touchable hoverable" @click="selected = !selected">
    <div class="selection">
      <img v-if="selected" src="@/assets/ic-page-switch-selected@2x.png" />
      <img v-else src="@/assets/ic-page-switch-normal@2x.png" />
    </div>

    <div class="content">
      <div class="medias">
        <div v-for="media in post.pictures" :key="media._id" class="media">
          <img loading="lazy" :src="media.thum" />
          <div v-if="media.type === MediaType.video" class="backdrop"></div>
          <img v-if="media.type === MediaType.video" class="video-tag" src="@/assets/Video.png" />
        </div>
      </div>

      <p>{{ post.title }}</p>
    </div>
  </div>
</template>

<script>
//@ts-check
import { MediaType } from '@perohub/libpero/dist/types'
import { ref, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selected = ref(props.modelValue)

    watch(selected, () => {
      emit('update:modelValue', selected.value)
    })

    watch(props, () => {
      selected.value = props.modelValue
    })

    return {
      selected,
      MediaType,
    }
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/base.less';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  overflow-x: scroll;
}

.selection {
  flex-shrink: 0;
  flex-grow: 0;

  img {
    width: 1.6em;
    height: 1.6em;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1em;
}

p {
  color: @black;
  min-height: 32px;
  font-size: 18px;
  font-weight: bolder;
}

.medias {
  display: flex;
  flex-wrap: nowrap;

  .media {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    width: 4em;
    height: 4em;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 4em;
      height: 4em;

      &.video-tag {
        position: initial;
        height: 3em;
        width: 3em;
        z-index: 1;
      }
    }

    .backdrop {
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
