<template>
  <div id="app" :class="{ 'mobile-app': isOnMobile, 'pc-app': !isOnMobile }" :style="containerStyle">
    <nav-bar v-show="navVisible"></nav-bar>

    <router-view v-slot="{ Component }" :style="{ paddingBottom: isOnMobile ? '4rem' : undefined }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.fullPath" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
    </router-view>

    <login></login>

    <tab-bar v-if="isOnMobile" />

    <pr-footer v-else v-show="footerVisible" class="footer"></pr-footer>

    <channel-collector v-model="showChannelCollector"></channel-collector>

    <circle-collector v-model="showCircleCollector"></circle-collector>

    <social-sharing v-model="showSocialSharing"></social-sharing>

    <payment-processor-picker v-model="showPaymentPicker"> </payment-processor-picker>

    <media-browser></media-browser>

    <toast :position="isOnMobile ? 'bottom-center' : 'top-right'"></toast>

    <toast position="bottom-center" group="withButtons">
      <template #message="slotProps">
        <div style="display: flex; flex-direction: column; width: 100%">
          <div class="text-center">
            <p>{{ slotProps.message.detail }}</p>
          </div>
          <div style="display: flex; width: 100%; gap: 1rem">
            <div v-if="slotProps.message.onReject" style="flex: 1">
              <Button
                class="p-button-secondary"
                style="width: 100%"
                :label="slotProps.message.rejectLabel || 'No'"
                @click="slotProps.message.onReject"
              >
              </Button>
            </div>
            <div v-if="slotProps.message.onConfirm" style="flex: 1">
              <Button
                class="p-button-success"
                style="width: 100%"
                :label="slotProps.message.confirmLabel || 'Yes'"
                @click="slotProps.message.onConfirm"
              ></Button>
            </div>
          </div>
        </div>
      </template>
    </toast>

    <scroll-top :threshold="500"></scroll-top>

    <confirm-dialog></confirm-dialog>

    <r18-cover v-if="showR18Cover" v-show="showR18Cover" @dismiss="showR18Cover = false"> </r18-cover>
  </div>
</template>

<script>
//@ts-check
import ChannelCollector from '@/components/ChannelCollector.vue'
import CircleCollector from '@/components/CircleCollector.vue'
import Login from '@/components/Login.vue'
import NavBar from '@/components/NavBar.vue'
import PaymentProcessorPicker from '@/components/PaymentProcessorPicker.vue'
import PrFooter from '@/components/PRFooter.vue'
import SocialSharing from '@/components/SocialSharing.vue'
import TabBar from '@/components/TabBar.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import Button from 'primevue/button'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import MediaBrowser from './components/MediaBrowser.vue'
import R18Cover from './components/R18Cover.vue'

import '@/assets/base.css'
import '@/assets/typo.css'
import 'primeicons/primeicons.css'

import { useApplication } from '@/pinia/application'
import { useAuthenticator } from '@/pinia/authenticator'
import { useChannelStore } from '@/pinia/channel'
import { useR18CoverStore } from '@/pinia/r18cover'
import { useHead } from '@unhead/vue'
import ScrollTop from 'primevue/scrolltop'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const uistates = useApplication()
    const channelStore = useChannelStore()
    const r18coverStore = useR18CoverStore()
    const authenticator = useAuthenticator()
    const route = useRoute()
    const { t } = useI18n()
    const { showToast } = useToast()

    const transitionName = ref('slide-left')

    useHead({
      title: 'Artworks Marketplace | Perohub',
    })

    const showChannelCollector = computed({
      get() {
        return uistates.showChannelCollector
      },
      set(value) {
        uistates.showChannelCollector = value
      },
    })

    const showCircleCollector = computed({
      get() {
        return uistates.showCircleCollector
      },
      set(value) {
        uistates.showCircleCollector = value
      },
    })

    const showSocialSharing = computed({
      get() {
        return uistates.socialSharingVisible
      },
      set(value) {
        uistates.socialSharingVisible = value
      },
    })

    const sourceChannel = computed(() => {
      return channelStore.sourceChannel
    })

    const showPaymentPicker = computed({
      get() {
        return uistates.paymentVisible
      },
      set(value) {
        uistates.paymentVisible = value
      },
    })

    const showR18Cover = computed({
      get() {
        return r18coverStore.r18coverVisible
      },
      set(value) {
        r18coverStore.$patch({ r18coverVisible: value })
      },
    })

    watch(route, (to, from) => {
      if ((to.path === '/' && from.path === '/i') || (to.path === '/i' && from.path === '/')) {
        return (transitionName.value = '')
      }

      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length

      transitionName.value = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    })

    const setChannelNumberIfNeeded = async () => {
      const channelNumber = route.query.cid
      await channelStore.setSourceChannelNumber(channelNumber)

      if (sourceChannel.value) {
        const message = t('fromChannel', {
          title: sourceChannel.value.title,
          owner: sourceChannel.value.owner.nickname,
        })

        showToast(
          {
            message,
          },
          'info',
        )
      }
    }

    onMounted(async () => {
      await setChannelNumberIfNeeded()
      await authenticator.checkAuth()
    })

    return {
      ...useResponsive(),
      showChannelCollector,
      showCircleCollector,
      showSocialSharing,
      channelStore,
      sourceChannel,
      showPaymentPicker,
      showR18Cover,
    }
  },
  computed: {
    footerVisible() {
      if (this.isOnMobile) {
        return false
      }

      return this.$route.matched.filter(({ path }) => path.indexOf('/comics') !== -1).length === 0
    },
    navVisible() {
      return this.$route.matched.filter(({ path }) => path.indexOf('/comics') !== -1).length === 0
    },
    containerStyle() {
      if (this.isOnMobile) {
        return {}
      }

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight
      return {
        minHeight: `${height}px`,
        paddingBottom: this.footerVisible ? '201px' : '0',
      }
    },
  },
  components: {
    Login,
    TabBar,
    NavBar,
    PrFooter,
    ChannelCollector,
    CircleCollector,
    SocialSharing,
    PaymentProcessorPicker,
    MediaBrowser,
    Toast,
    ScrollTop,
    ConfirmDialog,
    Button,
    R18Cover,
  },
}
</script>

<style lang="less">
@import '@/assets/base.less';

body {
  .scrollbar(@main, transparent);
}

.p-toast-top-right {
  bottom: unset !important;
}
</style>

<style lang="less" scoped>
.mobile-app {
  height: 100vh;
  height: 100dvh; // fix ios safari dosen't fucking respect 100vh
  max-width: 736px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.pc-app {
  background: white;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.view {
  position: absolute;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.footer {
  position: absolute;
  bottom: 0;
}

/* vue-router transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.icon {
  margin-top: 12px;
  color: white;
}

.p-scrolltop {
  inset-block-end: 4.2rem !important;
}
</style>
