<template>
  <pr-popup v-model="visible" class="withdraw-container">
    <template #header>
      <div class="head">
        <span v-if="currentUser.info.payoutMethod === 'stripe'" class="alipay">
          Payout with Stripe Express
        </span>
        <span v-else-if="currentUser.info.payoutMethod === 'tron'" class="alipay">
          Payout with trc20 USDT
        </span>
        <span v-else-if="currentUser.info.payoutMethod === 'paypal'" class="alipay">
          Payout with Paypal
        </span>
      </div>
    </template>

    <div class="withdraw-content">
      <div v-for="balance in balances" :key="balance.currency" class="content">
        <p>{{ balance.currency }}</p>
        <img class="reduce outline touchable hoverable" src="@/assets/icon_page_wallet_Reduce@2x.png"
          @click="minus(balance.currency)">
        <input v-model="withdraws[balance.currency]" type="number" class="gridcell">
        <img class="outline touchable hoverable" src="@/assets/icon_page_wallet_increase@2x.png"
          @click="add(balance.currency)">
      </div>

      <p class="tip">
        - Perohub takes {{ taxString }} application fees plus 3% exchange fees for revenues not in USD <br>
        - USD will be setteled at the time we transfer the payouts
      </p>
    </div>

    <template #footer>
      <div style="width: 100%; display: flex; gap: 1rem; align-items: center;">
        <pr-button class="cancel" @click="visible = false">
          {{ $t('cancel') }}
        </pr-button>
        <pr-button type="primary" class="submit" :loading="withdrawing" :disabled="!currentUser.payoutEnabled"
          @click="submitWithdraw">
          <span v-if="currentUser.info.payoutMethod === 'stripe'">
            Transfer to My Stripe Account
          </span>
          <span v-else-if="currentUser.info.payoutMethod === 'tron'">
            {{ $t('payout') }}
          </span>
          <span v-else-if="currentUser.info.payoutMethod === 'paypal'">
            Payout with Paypal
          </span>
        </pr-button>
      </div>
    </template>
  </pr-popup>
</template>

<script>
import PrButton from '@/components/PRButton.vue'
import { useResponsive } from '@/composables/responsive'
import { useUserPayout } from '@/composables/user_payout'
import { computed, watch } from 'vue'
import { useServerConfig } from '@/composables/server_config'
import { useCurrentUser } from '@/composables/user_data'
import PrPopup from '@/components/PRPopup.vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const visible = computed({
      set(value) {
        emit('update:modelValue', value)
      },
      get() {
        return props.modelValue
      }
    })

    return {
      ...useCurrentUser(),
      ...useResponsive(),
      visible,
      ...useServerConfig(),
      ...useUserPayout(),
    }
  },
  components: {
    PrButton,
    PrPopup,
  }
}
</script>

<style>
.withdraw-container.p-drawer {
  height: 40rem !important;
}

.withdraw-container .p-drawer-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>

<style scoped lang="less">
@import "@/assets/base.less";

.head {
  display: flex;
  justify-content: space-between;
  line-height: 30px;

  .alipay {
    font-size: 17px;
    color: @black;
  }
}

.withdraw-content {
  flex: 1;
}

.content {
  padding: 16px 3px;
  border-bottom: 1px solid @border;

  .gridcell {
    outline: none;
    font-size: 24px;
    color: @main;
    box-sizing: border-box;
    ;
    padding-left: 6px;
    width: 70%;
    border: 0px;
  }

  img {
    width: 24px;
    height: 24px;
    line-height: 10px;
    vertical-align: -3px;
  }
}

p {
  font-size: 12px;
  color: @black;
  padding-top: 10px;
}

.tip {
  color: @grey;
  padding-bottom: 10px;

  span {
    color: @blue;
  }
}

button.submit {
  flex: 1;
}

button.cancel {
  flex: 1;
}
</style>
