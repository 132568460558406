<template>
  <div
    v-if="!isOnMobile"
    class="container"
  >
    <circle-cell-desktop
      v-for="circle in my.circles"
      :key="circle._id"
      class="touchable hoverable circle"
      :circle="circle"
      @click="$router.push(`/circles/${circle._id}`)"
    ></circle-cell-desktop>
    <div
      v-if="join"
      class="empty"
    >
      <img src="@/assets/Page_empty_guider.png" />
      <p>TA 暂时没有的应援团</p>
    </div>
  </div>

  <div
    v-else
    class="wrap"
  >
    <router-link
      v-for="circle in my.circles"
      :key="circle._id"
      :to="`/circles/${circle._id}`"
    >
      <circle-cell
        class="touchable"
        :circle="circle"
      ></circle-cell>
    </router-link>

    <div
      v-if="join"
      class="empty"
    >
      <img src="@/assets/Page_empty_guider.png" />
      <p>他暂时没有的应援团</p>
    </div>
  </div>
</template>

<script>
import { getUserCircles } from '@/api/circle'
import CircleCell from '@/components/CircleCell.vue'
import CircleCellDesktop from '@/components/CircleCellDesktop.vue'
import { useResponsive } from '@/composables/responsive'
import { useToast } from '@/composables/toast'
import { useCurrentUser } from '@/composables/user_data'
import { useHead } from '@unhead/vue'

export default {
  setup() {
    useHead({
      title: 'Perohub',
    })

    return {
      ...useCurrentUser(),
      ...useResponsive(),
      ...useToast(),
    }
  },
  data() {
    return {
      my: {
        page: 1,
        hasMore: true,
        busy: false,
        circles: [],
      },
      limit: 30,
      join: false,
      loading: true,
    }
  },
  mounted() {
    this.getUserCircles()
  },
  methods: {
    getUserCircles() {
      getUserCircles(this.$route.params.userId)
        .then((circles) => {
          document.title = circles[0].author.nickname + '的应援团'
          this.my.busy = false
          if (circles.length === 0) {
            if (this.my.circles.length === 0) {
              this.join = true
            }
          } else {
            circles.forEach((circle) => this.my.circles.push(circle))
          }
        })
        .catch((err) => {
          this.my.busy = false

          console.error(err)

          this.showToast({
            message: err.message ? err.message : err,
          })
        })
    },
  },
  components: {
    CircleCellDesktop,
    CircleCell,
  },
}
</script>

<style scoped lang="less">
@import '@/assets/base.less';

.container {
  padding-bottom: 60px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.wrap {
  padding: 0px 0px 0px 0px;
  width: 800px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  margin: 0 auto;

  a {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  .empty {
    margin: 0 auto;
    margin-top: 10%;

    img {
      width: 80px;
      height: 80px;
      margin-left: 25%;
    }
  }
  .loading {
    margin: 0 auto;
    margin-top: 10%;
    color: @blue;
    font-size: 14px;
  }
}
</style>
